import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";

import { getMainCastingRequest, getMainRequest } from "types/main";

// 진행중인 캐스팅
const getMainCasting = async (getMainCastingRequest: getMainCastingRequest) => {
  return await requestToCastingVote
    .get(`home/castingcalls`, { params: getMainCastingRequest })
    .then((res) => res.data)
    
};

const useMainCasting = (getMainCastingRequest: getMainCastingRequest) => {
  return useInfiniteQuery({
    queryKey: ["mainCasting", getMainCastingRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMainCasting({
        ...getMainCastingRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 지금 뜨고있는 프로필 (캐스팅 픽)
const getMainCastingPick = async (getMainRequest: getMainRequest) => {
  return await requestToCastingVote
    .get(`home/profiles/hot`, { params: getMainRequest })
    .then((res) => res.data)
    
};

const useMainCastingPick = (getMainRequest: getMainRequest) => {
  return useInfiniteQuery({
    queryKey: ["mainCastingPick", getMainRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMainCastingPick({
        ...getMainRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 새로 올라온 프로필
const getMainCastingNew = async (getMainRequest: getMainRequest) => {
  return await requestToCastingVote
    .get(`home/profiles/new`, { params: getMainRequest })
    .then((res) => res.data)
    
};

const useMainCastingNew = (getMainRequest: getMainRequest) => {
  return useInfiniteQuery({
    queryKey: ["mainCastingNew", getMainRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMainCastingNew({
        ...getMainRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 배너 목록
const getBanner = async (type: "TOP" | "MAIN" | "ETC") => {
  return await requestToCastingVote
    .get(`banners`, { params: { type } })
    .then((res) => res)
    
};

const useBanner = (type: "TOP" | "MAIN" | "ETC") => {
  return useQuery({
    queryKey: ["banners", type],
    queryFn: () => getBanner(type),
  });
};

export { useMainCasting, useMainCastingPick, useMainCastingNew, useBanner };
