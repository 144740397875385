import React, { useState } from "react";
import { ReactComponent as homeOn } from "assets/home_on.svg";
import { ReactComponent as homeOff } from "assets/home_off.svg";
import { ReactComponent as staffOn } from "assets/staff_on.svg";
import { ReactComponent as staffOff } from "assets/staff_off.svg";
import { ReactComponent as artistOn } from "assets/artist_on.svg";
import { ReactComponent as artistOff } from "assets/artist_off.svg";
import { ReactComponent as moreOn } from "assets/more_on.svg";
import { ReactComponent as moreOff } from "assets/more_off.svg";
import { ReactComponent as castingOn } from "assets/casting_on.svg";
import { ReactComponent as castingOff } from "assets/casting_off.svg";
import {
  NavLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import useLoginInfo from "stores/loginInfo";

const TabBar = () => {
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const value = searchParams?.get("value");
  const type = searchParams?.get("type");
  const { isLogin } = useLoginInfo();


  const [currentTab, setCurrentTab] = useState("홈");

  const tabBarItem = [
    { id: 0, name: "홈", url: "/", icon: homeOff, iconOn: homeOn },
    {
      id: 1,
      name: "캐스팅",
      url: "/casting",
      icon: castingOff,
      iconOn: castingOn,
    },
    {
      id: 2,
      name: "아티스트",
      url: "/artist",
      icon: artistOff,
      iconOn: artistOn,
    },
    {
      id: 3,
      name: "STAFF",
      url: "/staff",
      icon: staffOff,
      iconOn: staffOn,
    },
    {
      id: 4,
      name: "더보기",
      url: isLogin ? "/more" : "/login",
      icon: moreOff,
      iconOn: moreOn,
    },
  ];
  if (location.pathname === `/banner`) return null;

  if (location.pathname === `/kmc/callback`) return null;
  if (location.pathname === `/signup/kakao`) return null;

  if (location.pathname === `/point`) return null;
  if (location.pathname === `/point/charge`) return null;
  if (location.pathname === `/point/charge/${params?.paymentId}`) return null;
  if (location.pathname === `/point/history/use`) return null;
  if (location.pathname === `/point/history/charge`) return null;
  if (location.pathname === `/membership/subscribe`) return null;
  if (location.pathname === `/membership/history`) return null;
  if (location.pathname === `/membership`) return null;
  if (location.pathname === `/membership/subscribe`) return null;
  if (location.pathname === `/membership/cancel`) return null;
  if (location.pathname === `/membership/my`) return null;
  if (location.pathname === `/membership/payment-method`) return null;

  if (location.pathname === "/mypage") return null;

  if (location.pathname === "/login") return null;
  if (location.pathname === "/signup") return null;
  if (location.pathname === "/find/password") return null;
  if (location.pathname === "/find/id") return null;
  if (location.pathname === "/find/verify") return null;
  if (location.pathname === `/withdrawal`) return null;

  if (location.pathname === "/artist/profile") return null;
  if (location.pathname === `/artist/profile/edit`) return null;
  if (location.pathname === "/artist/my") return null;
  if (location.pathname === "/artist/send") return null;
  if (location.pathname === "/artist/suggest") return null;
  if (
    location.pathname === "/artist/share" &&
    location.search === `?value=${value}&type=${type}`
  ) {
    return null;
  }
  if (location.pathname === `/artist/${params?.artistId}`) return null;

  if (location.pathname === `/casting/my/${params?.itemId}`) return null;
  if (location.pathname === `/casting/${params?.itemId}`) return null;

  if (location.pathname === `/staff/${params?.staffId}`) return null;
  if (location.pathname === `/staff/suggest`) return null;
  if (location.pathname === `/staff/feedback/${params?.artistId}`) return null;
  if (location.pathname === `/staff/received/${params?.artistId}`) return null;
  if (location.pathname === `/staff/casting/my`) return null;
  if (location.pathname === `/staff/castingcall/${params.id}`) return null;
  if (location.pathname === `/staff/my`) return null;
  if (location.pathname === `/staff/reference`) return null;
  if (location.pathname === `/staff/casting/process/${params?.id}`) return null;
  if (location.pathname === `/staff/filmo/my/${params?.productId}`) return null;
  if (location.pathname === `/staff/filmo/${params?.staffId}`) return null;
  if (location.pathname === `/staff/profile`) return null;
  if (location.pathname === `/staff/profile/edit`) return null;
  if (location.pathname === `/staff/post/edit/${params?.id}`) return null;
  if (location.pathname === `/staff/casting-call/edit/${params?.id}`)
    return null;
  if (location.pathname === `/staff/post`) return null;
  if (location.pathname === `/staff/casting-call`) return null;
  if (location.pathname === `/staff/received`) return null;
  if (location.pathname === `/staff/feedback`) return null;

  if (location.pathname === `/manage/artist`) return null;
  if (location.pathname === `/manage/artist/profile`) return null;
  if (location.pathname === `/manage/send`) return null;
  if (location.pathname === `/manage/suggest`) return null;
  if (location.pathname === `/manage/my`) return null;
  if (location.pathname === `/manage/profile/edit`) return null;
  if (location.pathname === `/manage/artist/detail/${params?.artistId}`) return null;
  if (location.pathname === `/manage/artist/${params?.artistId}/edit`) return null;


  if (location.pathname === `/suggest/product`) return null;
  if (location.pathname === `/suggest/castingcall`) return null;
  if (location.pathname === `/suggest/description`) return null;
  if (location.pathname === `/suggest/deadline`) return null;

  if (location.pathname === `/private`) return null;
  if (location.pathname === `/private-pay`) return null;
  if (location.pathname === `/terms`) return null;
  if (location.pathname === `/marketing-optin`) return null;

  return (
    <div className="fixed pb-[10px] w-full min-w-[280px] z-[51] max-w-[400px] bg-Gray00 pt-2 bottom-0 border rounded-tr-[20px] rounded-tl-[20px]">
      <div className="flex items-center gap-1.5 justify-between RCaption10">
        {tabBarItem.map((item) => {
          const IconOff = item.icon;
          const IconOn = item.iconOn;
          return (
            <NavLink
              onClick={() => {
                setCurrentTab(item.name);
                if (currentTab === item.name) {
                  window.scroll({
                    top: 0,
                    behavior: "smooth",
                  });
                }
              }}
              key={item.id}
              className={({ isActive }) =>
                `${isActive ? "text-Gray09" : "text-Gray05"
                } items-center flex flex-col gap-1.5 flex-1 mx-auto cursor-pointer`
              }
              to={`${item.url}`}
            >
              {({ isActive }) => (
                <>
                  {isActive ? (
                    <IconOn className="mx-auto w-full" />
                  ) : (
                    <IconOff className="mx-auto" />
                  )}
                  <div>{item.name}</div>
                </>
              )}
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default TabBar;
