import { create } from "zustand";
import { persist } from "zustand/middleware";
import requestToCastingVote from "apis/api";

interface authInfoType {
  memberId?: null | number;
  userId?: string;
  birthDate?: string;
  gender: { code: string; label: string } | null;
  name?: string;
  profileId?: null | number;
  profileName?: string;
  thumbnailUrl?: string;
  authorities?: string[];
  favoriteArtistProfileIds?: number[];
  favoriteCastingCallIds?: number[];
  favoriteProductionIds?: number[];
  favoriteStaffProfileIds?: number[];
  role: { code: string; label: string } | null;
}

const initialLoginInfo: authInfoType = {
  memberId: null,
  userId: "",
  birthDate: "",
  gender: null,
  name: "",
  profileId: null,
  profileName: "",
  thumbnailUrl: "",
  authorities: [],
  favoriteArtistProfileIds: [],
  favoriteCastingCallIds: [],
  favoriteProductionIds: [],
  favoriteStaffProfileIds: [],
  role: null,
};

interface LoginInfoState {
  loginInfo: authInfoType;
  isLogin: boolean;
  isLoading: boolean;
  setIsLogin: (state: boolean) => void;
  setLoginInfo: () => void;
  deleteLoginInfo: () => void;
}

const useLoginInfo = create<LoginInfoState>()(
  persist(
    (set) => ({
      loginInfo: initialLoginInfo,
      isLogin: false,
      isLoading: true,

      setIsLogin: (state: boolean) => {
        set({ isLogin: state });
      },

      setLoginInfo: () => {
        requestToCastingVote
          .get(`auth/info`)
          .then((res) => {
            set({ loginInfo: res.data.data, isLogin: true });
          })
          .catch(() => {
            set({
              loginInfo: initialLoginInfo,
              isLogin: false,
            });
          })
          .finally(() => {
            set({
              isLoading: false,
            });
          });
      },

      deleteLoginInfo: () =>
        set({
          loginInfo: initialLoginInfo,
          isLogin: false,
        }),
    }),
    {
      name: "login-info-storage",
      partialize: (state) => ({
        isLogin: state.isLogin,
      }),
    }
  )
);
export default useLoginInfo;
