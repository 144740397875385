import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { useCertVerify, useFindUserId, useRequestCert } from "apis/user";

import ButtonBackground from "components/ButtonBackground";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Input from "components/Input";
import { FillButton } from "components/FillButton";
import { LineButton } from "components/LineButton";
import { Timer } from "components/Timer";
import TitleWrap from "components/TitleWrap";

const MemberFindId = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [certNum, setCertNum] = useState<string>("");
  const [isCert, setIsCert] = useState(false);
  const [isTimer, setIsTimer] = useState<boolean | null>(null);

  const [myIdList, setMyIdList] = useState([]);

  const { mutate, isPending } = useRequestCert();
  const { mutate: verifyMutate } = useCertVerify();

  const handleTimerEnd = () => {
    if (isCert) {
      setIsTimer(false);
    } else {
      setIsTimer(null);
    }
  };

  const { mutate: findUserId } = useFindUserId();

  const handleVerifyCert = () => {
    verifyMutate(
      { phoneNumber, certNum },
      {
        onSuccess: () => {
          setIsCert(true);
          setIsTimer(null);

          if (state === "ID") {
            findUserId(
              { phoneNumber: phoneNumber, certNum: certNum },
              {
                onSuccess: (data) => {
                  setMyIdList(data.data);
                },
              }
            );
          }
        },
      }
    );
  };

  return (
    <div>
      <HeaderWithBackButton title={"휴대폰 인증"} />
      <div className="px-5 pt-5 flex flex-col gap-10 pb-24">
        <TitleWrap title={"휴대폰번호"}>
          <div className="flex items-center gap-2 w-full">
            <Input
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setPhoneNumber(value);
              }}
              value={phoneNumber}
              placeholder="휴대폰번호"
              onKeyDown={(e) => {
                if (["e", "E", "+", "-", "."].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              type={"text"}
              inputMode="numeric"
            />
            <LineButton
              onClick={() => {
                mutate(phoneNumber, {
                  onSuccess: () => {
                    setIsTimer(true);
                  },
                });
              }}
              className="min-w-[90px]"
              disabled={isPending || !phoneNumber}
              text="인증요청"
              variant="normal"
              size="L"
            />
          </div>
        </TitleWrap>
        <TitleWrap title={"인증번호"}>
          <div className="flex items-center gap-2">
            <Input
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, "");
                setCertNum(value);
              }}
              value={certNum}
              placeholder="인증번호"
              onKeyDown={(e) => {
                if (["e", "E", "+", "-", "."].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              type={"text"}
              className={`${isTimer && "!pr-[68px]"}`}
              inputMode="numeric"
            >
              {isTimer && (
                <Timer
                  className="absolute right-4 top-3"
                  onTimerEnd={handleTimerEnd}
                />
              )}
            </Input>
            <LineButton
              disabled={!isTimer}
              onClick={() => {
                handleVerifyCert();
              }}
              className="min-w-[90px]"
              text="인증확인"
              variant="normal"
              size="L"
            ></LineButton>
          </div>
        </TitleWrap>
      </div>
      <ButtonBackground>
        <FillButton
          onClick={() => {
            navigate(`/find/${state === "ID" ? "id" : "password"}`, {
              state:
                state === "ID"
                  ? myIdList
                  : { certNum: certNum, phoneNumber: phoneNumber },
            });
          }}
          variant="Membership"
          disabled={!isCert}
          text="계속"
          size="B"
        />
      </ButtonBackground>
    </div>
  );
};

export default MemberFindId;
