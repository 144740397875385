import React, { useEffect, useMemo, useState } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';

import { useInView } from 'react-intersection-observer';
import { useNavigate, useParams } from 'react-router';
import CastingcallCard from './MyCasting/_components/CastingcallCard';
import BottomPopup from 'components/BottomPopup';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import { TintButton } from 'components/TintButton';
import { useMyCastingcallsByProduction } from 'apis/my';
import { useChangeCastingcallStatus } from 'apis/castingcall';

const StaffMyCastingcall = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [deadline, setDeadline] = useState(false);
    const [selectedCastingcall, setSelectedCastingcall] = useState<myCastingcall>()

    const productId = Number(id);
    const { data, fetchNextPage, hasNextPage, refetch } = useMyCastingcallsByProduction({ id: productId })
    const { ref, inView } = useInView({
        threshold: 0.5,
    });
    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const allLists = useMemo(
        () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
        [data]
    );

    const { mutate } = useChangeCastingcallStatus();

    return (
        <div>
            <HeaderWithBackButton title={''} >
                <div
                    onClick={() => {
                        navigate("/staff/casting-call", {
                            state: { prodId: productId }
                        });
                    }}
                    className={`BCaption14 text-Blue04 flex items-center gap-2 cursor-pointer`}
                >
                    <div>공고등록</div>
                </div>
            </HeaderWithBackButton>
            <div className="pb-24">
                {allLists.length === 0 ? (
                    <div className="mt-36 items-center text-center">
                        <div className="text-Gray05 RBody14">캐스팅 중인 공고가 없어요.</div>
                    </div>
                ) : (
                    <>
                        {allLists.map((castingcallItem: myCastingcall) => {
                            return (
                                <CastingcallCard
                                    onClick={() => {
                                        navigate(
                                            `/staff/casting/process/${castingcallItem.castingCallId}`,
                                            {
                                                state: {
                                                    title:
                                                        castingcallItem.title,
                                                    castingCallId:
                                                        castingcallItem.castingCallId,
                                                    status:
                                                        castingcallItem
                                                            .status.code,
                                                },
                                            }
                                        );
                                    }}
                                    onDeadlineClick={() => {
                                        setSelectedCastingcall(castingcallItem)
                                        setDeadline(true);
                                    }}
                                    key={castingcallItem.castingCallId}
                                    deadlineDate={castingcallItem.deadlineDate}
                                    title={castingcallItem.title}
                                    castingcallId={castingcallItem.castingCallId}
                                    status={castingcallItem?.status?.code} />
                            )
                        })}
                        {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                    </>
                )}
            </div>
            {deadline &&
                <BottomPopup onClose={() => {
                    setDeadline(false);
                    setSelectedCastingcall(undefined);
                }}>
                    <div className="text-Gray09 BBody20">선택한 공고를 마감할까요 ?</div>
                    <div className="MBody16 my-4 text-Gray05">
                        공고를 마감하면 공고 수정 및 캐스팅을 진행할 수 없어요. 받은 프로필
                        피드백도 함께 마감돼요.
                    </div>
                    <ButtonBackground>
                        <FillButton
                            onClick={() => {
                                setDeadline(false);
                                setSelectedCastingcall(undefined);
                            }}
                            className="w-full"
                            text={"닫기"}
                            variant="Secondary"
                            size="L"
                        />
                        <TintButton
                            onClick={() => {
                                mutate(
                                    { castingCallId: selectedCastingcall?.castingCallId!, status: "CLOSED" },
                                    {
                                        onSuccess: () => {
                                            setDeadline(false)
                                            setSelectedCastingcall(undefined);
                                            refetch();
                                        },
                                    }
                                );
                            }}
                            text={"마감하기"}
                            className="w-full"
                            variant="delete"
                            size="L"
                        ></TintButton>
                    </ButtonBackground>
                </BottomPopup>
            }
        </div>
    );
};

export default StaffMyCastingcall;

interface myCastingcall {
    castingCallId: number
    title: string;
    type: {
        code: string;
        label: string;
    } | null;
    deadlineDate: string;
    deadlineType: {
        code: string;
        label: string;
    } | null;
    minAge: number | null;
    maxAge: number | null;
    ageType: null,
    gender: {
        code: string;
        label: string;
    } | null;
    castingCallRole: {
        code: string;
        label: string;
    } | null;
    thumbnailUrl: string | null;
    format: {
        code: string;
        label: string;
    } | null;
    status: {
        code: string;
        label: string;
    };
};
