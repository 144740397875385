import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ImgUpload from "components/ImgUpload";
import Input from "components/Input";
import Textarea from "components/Textarea";
import TitleWrap from "components/TitleWrap";
import { Toast } from "hooks/useToast";
import { TYPE_LIST } from "utils/type";
import { ProductionData } from "type/staff";
import { ReactComponent as XButton } from "assets/xbutton.svg";
import ButtonBackground from "components/ButtonBackground";
import { TYPE_LABELS } from "components/SelectBox";
import { useMyProduction } from "apis/my";
import { useUpdateProduction } from "apis/production";
import { updateProduct } from "types/product/product";
import { useUploadImage } from "apis/attachment";

const UpdateProduction = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isNew, setIsNew] = useState(false);
  const [postData, setPostData] = useState<updateProduct>({
    productionId: null,
    title: "",
    format: "",
    description: "",
    directorName: "",
    companyName: "",
    attachmentId: null,
    addAttachmentId: null,
    removeAttachmentId: null,
  });

  const { data } = useMyProduction(Number(id));
  const prodDetail = data?.data;

  useEffect(() => {
    if (prodDetail) {
      setPostData({
        ...postData,
        productionId: prodDetail.productionId,
        title: prodDetail.title,
        format: prodDetail.format.code,
        description: prodDetail.description,
        attachmentUrl: prodDetail.thumbnailUrl,
        companyName: prodDetail.companyName,
        directorName: prodDetail.directorName,
        attachmentId: prodDetail.thumbnailId,
      });
    }
  }, [prodDetail]);

  const { mutate: uploadImages } = useUploadImage();

  const { mutate: updateProduction } = useUpdateProduction();

  const imgUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      uploadImages(files, {
        onSuccess: (res) => {
          setPostData({
            ...postData,
            addAttachmentId: res?.data[0].attachmentId,
            attachmentUrl: res?.data[0].attachmentUrl,
          });
          setIsNew(true);
        },
      });
    }
  };

  const valid = (postData: ProductionData) => {
    if (!postData.title) return "작품제목은 필수입니다.";
    if (!postData.format) return "분야를 선택해주세요.";
    if (!postData.description) return "작품설명을 입력해주세요.";
    return null;
  };

  return (
    <>
      <HeaderWithBackButton title={`작품수정`} />
      <div className="p-5 pb-24">
        <div className="flex flex-col gap-10">
          <TitleWrap title={"대표사진 업로드"}>
            {postData.attachmentUrl ? (
              <div className="relative">
                <img
                  className="w-full h-[450px] rounded-[10px] object-cover"
                  src={`${process.env.REACT_APP_MEDIA_URL}${postData?.attachmentUrl}`}
                  alt="ProfileImg"
                />
                <XButton
                  onClick={() => {
                    if (isNew) {
                      setPostData({
                        ...postData,
                        addAttachmentId: null,
                        attachmentUrl: "",
                      });
                    } else {
                      setPostData({
                        ...postData,
                        removeAttachmentId: postData.attachmentId,
                        attachmentUrl: "",
                        attachmentId: null,
                      });
                    }
                  }}
                  className="absolute cursor-pointer top-4 right-4"
                />
              </div>
            ) : (
              <ImgUpload
                accept="image/jpg,image/png,image/jpeg"
                onChange={(e) => {
                  imgUpload(e);
                }}
                title="대표 사진 업로드"
                id={"Prod_profile"}
              ></ImgUpload>
            )}
          </TitleWrap>
          <TitleWrap require title={"작품제목"}>
            <Input
              value={postData?.title}
              onChange={(e) => {
                setPostData({ ...postData, title: e.target.value });
              }}
              type={"text"}
              placeholder="작품제목"
            />
          </TitleWrap>
          <TitleWrap require title={"분야"}>
            <Dropdown
              innerText={TYPE_LABELS[postData.format || ""] || "선택"}
              width="w-full"
            >
              <div>
                {TYPE_LIST.map((item) => {
                  return (
                    <li
                      onClick={() => {
                        setPostData({ ...postData, format: item.role });
                      }}
                      key={item.id}
                    >
                      {item.title}
                    </li>
                  );
                })}
              </div>
            </Dropdown>
          </TitleWrap>
          <div className="flex items-center gap-4">
            <TitleWrap title={"감독"}>
              <Input
                value={postData?.directorName}
                onChange={(e) => {
                  setPostData({ ...postData, directorName: e.target.value });
                }}
                type={"text"}
                placeholder="감독"
              />
            </TitleWrap>
            <TitleWrap title={"제작사"}>
              <Input
                value={postData?.companyName}
                onChange={(e) => {
                  setPostData({ ...postData, companyName: e.target.value });
                }}
                type={"text"}
                placeholder="제작사"
              />
            </TitleWrap>
          </div>
          <TitleWrap require title={"작품설명"}>
            <Textarea
              value={postData?.description}
              onChange={(e) => {
                setPostData({ ...postData, description: e.target.value });
              }}
              className="w-full h-[210px]"
              placeholder="작품설명"
              maxlength={500}
            />
          </TitleWrap>
        </div>
        <ButtonBackground>
          <Button
            onClick={() => {
              const validError = valid(postData);
              if (validError !== null) {
                Toast.error(validError);
              } else {
                updateProduction(postData, {
                  onSuccess: () => {
                    Toast.success("작품이 수정되었어요");
                    navigate("/staff/casting/my", { replace: true });
                  },
                });
              }
            }}
            className="w-full h-12 bg-Blue04 text-white border-none"
            text={"수정하기"}
          ></Button>
        </ButtonBackground>
      </div>
    </>
  );
};

export default UpdateProduction;
