import React, { useEffect, useMemo } from "react";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import { useNavigate } from "react-router";
import { useMainCastingPick } from "apis/main";

const ArtistPick = () => {
  const navigate = useNavigate();
  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  const { data, fetchNextPage, hasNextPage } = useMainCastingPick({});

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const pickLists = useMemo(
    () =>
      data ? data?.pages?.flatMap((staff) => staff.data.content) : [],
    [data]
  );

  return (
    <>
      <HeaderWithBackButton title={"캐스팅보트 P!ck"} />
      <div className="pb-24 p-5">
        <div className="grid grid-cols-3 gap-y-4 gap-x-2">
          {pickLists?.map((item: any, index: number) => {
            return (
              <ArtistListCard
                key={index}
                onClick={() => {
                  navigate(`/artist/${item?.profileId}`);
                }}
                isMembership
                src={item.thumbnailUrl}
                title={item.profileName}
                subTitle={`${splitBirth(item?.birthDate)}`}
                description={
                  item.productionTitle ? item.productionTitle : "미공개작품"
                }
              />
            );
          })}
        </div>
        {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
      </div>
    </>
  );
};

export default ArtistPick;
