import React, { useEffect, useState } from "react";

import { ReactComponent as Bookmark } from "../assets/Bookmark.svg";
import { ReactComponent as Share } from "../assets/share.svg";

import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Skeleton from "components/Skeleton";
import Modal from "components/Modal";
import { handleCopyClipBoard } from "utils/onCopyClipBoard";
import ScrollToTop from "components/ScrollToTop";
import ArtistInfoCard from "./ArtistDetail/_components/ArtistInfoCard";
import ArtistAttachments from "./ArtistDetail/_components/ArtistAttachments";
import ArtistDesc from "./ArtistDetail/_components/ArtistDesc";
import ArtistFilmographies from "./ArtistDetail/_components/ArtistFilmographies";
import { useArtistProfile } from "apis/profile";
import { useShareLink } from "apis/profileShare";
import useLoginInfo from "stores/loginInfo";

const ArtistDetail = () => {
  const navigate = useNavigate();

  const [tabId, setTabId] = useState(0);

  const profileTab = [
    { id: 0, title: "프로필" },
    // { id: 1, title: "포트폴리오" },
  ]
  const [copyModal, setCopyModal] = useState(false);
  const [profileId, setProfileId] = useState<null | number>(null);

  const { loginInfo } = useLoginInfo();

  const { artistId } = useParams();

  const { data, isFetching, refetch } = useArtistProfile(
    Number(artistId) as number
  );

  const ArtistData = data?.data;

  useEffect(() => {
    refetch();
  }, [artistId, refetch]);

  const { data: link } = useShareLink({
    id: profileId,
    type: "PROFILE",
    period: "MONTH",
  });

  return (
    <>
      <HeaderWithBackButton title={""}>
        {/* <Bookmark className="cursor-pointer" /> */}
        <Share
          onClick={() => {
            setCopyModal(true);
            setProfileId(ArtistData?.profileId!);
          }}
          className="cursor-pointer"
        />
      </HeaderWithBackButton>
      {isFetching ? (
        <Skeleton type="PROFILE_DETAIL" />
      ) : (
        <div className="pt-5 pb-24">
          <ScrollToTop />
          <div className="w-full">
            <ArtistInfoCard
              artistName={ArtistData?.profileName!}
              artistImageUrl={ArtistData?.thumbnailUrl!}
              jobs={ArtistData?.jobs!}
              birth={ArtistData?.birthDate!}
              height={ArtistData?.basicInfo.height!}
              weight={ArtistData?.basicInfo.weight!}
              instagram={ArtistData?.snsInfo.instagram!}
              youtube={ArtistData?.snsInfo.youtube!}
              memberId={ArtistData?.memberId}
              onClickOffer={() => {
                if (loginInfo?.profileId === null) {
                  navigate("/staff/my");
                } else {
                  navigate("/suggest/product", {
                    state: {
                      memberId: ArtistData?.memberId,
                      profileId: ArtistData?.profileId,
                      artistName: ArtistData?.profileName,
                    },
                  });
                }
              }}
            />
            <div className="border-b border-Gray-3 mt-4">
              <div className="px-5 flex items-start gap-4 BCaption14">
                {profileTab.map((item) => {
                  return (
                    <div key={item.id}
                      onClick={() => { setTabId(item.id) }}
                      className={`pb-4 cursor-pointer px-1 ${item.id === tabId ? "border-b-2 border-Gray09 text-Gray09" : "border-none text-Gray04"}`}>
                      {item.title}
                    </div>
                  )
                })}
              </div>
            </div>
            {tabId === 0 ? (
              <div>
                <ArtistAttachments
                  attachments={ArtistData?.attachments!}
                  videoLinks={ArtistData?.videoLinks!} />
                <ArtistDesc
                  hobbies={ArtistData?.hobbies!}
                  address={ArtistData?.basicInfo.address.sido!}
                  education={ArtistData?.basicInfo.education!}
                  agency={ArtistData?.basicInfo.agency!}
                  introduce={ArtistData?.introduce!}
                />
                {ArtistData?.filmographies?.length! > 0 &&
                  <ArtistFilmographies filmographies={ArtistData?.filmographies!} />
                }
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>

      )}
      {copyModal && (
        <Modal
          onClose={() => {
            setProfileId(null);
            setCopyModal(false);
          }}
          title="공유하기"
          subTitle="공유된 링크는 30일 동안 유효해요."
          closeButton
        >
          <Button
            onClick={() => {
              handleCopyClipBoard(
                `${process.env.REACT_APP_URL}/artist/share?value=${link
                  ?.data.value!}&type=PROFILE`
              );
              setCopyModal(false);
            }}
            className="w-full border border-Gray03 text-Gray05"
            text={"링크 복사하기"}
          ></Button>
        </Modal>
      )}
    </>
  );
};

export default ArtistDetail;

