import React, { useEffect, useState } from "react";
import HeaderWithBackButton from "./HeaderWithBackButton";
import { LineButton } from "./LineButton";
import { useLocation, useNavigate } from "react-router";
import { formatDateWithTime } from "hooks/useTodayWithNextMonth";
import { Toast } from "hooks/useToast";
import BottomPopup from "./BottomPopup";
import ButtonBackground from "./ButtonBackground";
import { FillButton } from "./FillButton";
import { TintButton } from "./TintButton";
import { useCancelPayment } from "apis/payment";

const PointChargeDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [cancelDetail, setCancelDetail] = useState(false);

  const chargeInfo = [
    { id: 0, title: "주문번호" },
    { id: 1, title: "날짜" },
    { id: 2, title: "충전 내역" },
    { id: 3, title: "충전 금액" },
    { id: 4, title: "결제 수단" },
  ];

  const cancelInfo = [
    { id: 0, title: "주문번호", data: state?.orderId },
    {
      id: 1,
      title: "구매상품",
      data: `${Number(state?.price)?.toLocaleString()}포인트`,
    },
    {
      id: 2,
      title: "결제일시",
      data: formatDateWithTime(state?.paymentDate),
    },
    {
      id: 3,
      title: "결제금액",
      data: `${Number(state?.price)?.toLocaleString()}원`,
    },
  ];

  const { mutate: cancelPayment, isPending } = useCancelPayment();

  useEffect(() => {
    if (!state?.cardName && !state?.orderAmount) {
      Toast.error("비정상적인 접근입니다.");
      navigate("/point", { replace: true });
    }
  }, []);

  return (
    <div>
      {cancelDetail && (
        <BottomPopup
          onClose={() => {
            setCancelDetail(false);
          }}
        >
          <div className="BBody20">결제를 취소 할까요 ?</div>
          <div className="flex flex-col gap-4 my-4">
            {cancelInfo.map((item, i) => {
              return (
                <div
                  key={item.id}
                  className="w-full flex justify-between items-start break-words"
                >
                  <div className="RBody16 text-Gray05">{item?.title}</div>
                  <div
                    className={`MBody16 max-w-[256px] ${item.id === 3 && "text-Blue04"
                      }`}
                  >
                    {item?.data}
                  </div>
                </div>
              );
            })}
          </div>
          <ButtonBackground className="gap-2">
            <FillButton
              onClick={() => {
                setCancelDetail(false);
              }}
              className="w-full"
              variant="Secondary"
              size="L"
              text="닫기"
            />
            <TintButton
              onClick={() => {
                cancelPayment(
                  { paymentId: state?.paymentId },
                  {
                    onSuccess: () => {
                      Toast.success("결제 취소됐어요");
                      navigate(-1);
                    },
                    onError: () => {
                      setCancelDetail(false);
                    },
                  }
                );
              }}
              isLoading={isPending}
              className="w-full"
              variant="delete"
              size="L"
              text="결제취소하기"
            />
          </ButtonBackground>
        </BottomPopup>
      )}
      <HeaderWithBackButton title={""} />
      <div className="p-5 pb-24">
        <div className="flex rounded-md border border-Gray02">
          <div className="flex flex-col RCaption14 max-w-[104px] text-right pl-2 text-Gray05 bg-Gray01 flex-1">
            {chargeInfo?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="px-4 flex flex-col justify-center last:border-none border-b h-12"
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          <div className="flex flex-1 flex-col max-w-[256px] pr-2 RCaption14 text-left text-Gray09 break-words">
            <div className="px-4 flex flex-col justify-center border-b h-12 ">
              {state?.orderId}
            </div>
            <div className="px-4 flex flex-col justify-center border-b h-12">
              {formatDateWithTime(state?.paymentDate)}
            </div>
            <div className="px-4 flex flex-col justify-center border-b h-12 BCaption14">
              {state?.price}포인트
            </div>
            <div
              className={`px-4 flex flex-col justify-center border-b h-12 BCaption14  ${state?.canceled ? "text-Error" : "text-Blue04"
                }`}
            >
              {state?.canceled ? "취소완료" : `${state?.price}원`}
            </div>
            <div className="px-4 flex flex-col justify-center h-12">
              {state?.cardName}
            </div>
          </div>
        </div>

        <div className="mt-10 mb-4">
          <LineButton
            disabled={state?.canceled}
            onClick={() => {
              setCancelDetail(true);
            }}
            variant={"delete"}
            size="M"
            text="결제취소"
          />
        </div>
        <div className="RCaption14 text-Gray05">
          <ol className="list-disc list-inside">
            <li>
              결제취소는 포인트를 사용하지 않은 경우 수수료 10%를 제외한 금액만 결제하신
              카드로 환불돼요
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default PointChargeDetail;
