import React from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { bannerItems } from './BannerItems';

const Banners = () => {


    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={'전체보기'} />
            <div className='flex flex-col gap-1'>
                {bannerItems.map((item) => {
                    return (
                        <img
                            key={item.url}
                            onClick={() => {
                                window.open(
                                    item.url,
                                    "noopener,noreferrer"
                                );
                            }}
                            className="cursor-pointer"
                            src={item.src}
                            alt=""
                        />
                    )
                })}
            </div>
        </div>
    );
};

export default Banners;