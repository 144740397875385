import React, { useEffect, useMemo } from 'react';

import { useInView } from 'react-intersection-observer';

import { useMyCastingcallsByProduction } from 'apis/my';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { useLocation, useNavigate } from 'react-router-dom';

const SuggestMyCastingcall = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const suggestData = location?.state;

    const { productId } = suggestData;

    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useMyCastingcallsByProduction({ status: "ACTIVE", id: productId });

    const { ref, inView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const castingcalls = useMemo(
        () =>
            data ? data?.pages?.flatMap((staff) => staff.data.content) : [],
        [data]
    );

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                제안할 공고를 선택해주세요
            </div>
            {castingcalls.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">제안할 공고가 없습니다.</div>
                </div>
            ) : (
                <>
                    {castingcalls?.map((item) => {
                        return (
                            <div onClick={() => {
                                navigate('/suggest/description', {
                                    state: { castingCallId: item.castingCallId, ...suggestData },
                                    replace: true,
                                })
                            }} key={item.castingCallId} className='p-5 cursor-pointer flex flex-col items-start gap-1 border-b border-Gray02'>
                                <div className='MCaption12 text-Gray05'>
                                    {item.deadlineDate || "캐스팅 확정 시"} 마감
                                </div>
                                <div className='MBody16 text-Gray09'>{item.title}</div>
                            </div>
                        )
                    })}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </>
            )}
        </div>
    );
};

export default SuggestMyCastingcall;