import { useMutation } from "@tanstack/react-query";
import requestToCastingVote from "./api";

import { Toast } from "hooks/useToast";
import imageCompression from "browser-image-compression";

const getImgUpload = async (image: File) => {
  const resizingBlob = await imageCompression(image, {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
  });
  const resizingFile = new File([resizingBlob], image.name, {
    type: image.type,
  });
  return resizingFile;
};

// 첨부 파일 업로드
const uploadFile = async (formData: FormData): Promise<UploadResponse> => {
  return await requestToCastingVote
    .post("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

const useUploadFile = () => {
  return useMutation({
    mutationFn: (fileList: FileList) => {
      // 10MB
      const FILE_SIZE_MB = 10 * 1024 * 1024;
      // 100MB
      const TOTAL_FILE_SIZE_MB = 100 * 1024 * 1024;
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > FILE_SIZE_MB) {
          Toast.error(`파일 크기가 10MB를 초과합니다.`);
          throw new Error(`파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_FILE_SIZE_MB) {
        Toast.error(`총 파일 크기가 100MB를 초과합니다.`);
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }
      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadFile(formData);
    },
  });
};

// 사진 파일 업로드
const uploadImage = async (formData: FormData): Promise<UploadResponse> => {
  return await requestToCastingVote
    .post("/upload/image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

const useUploadImage = () => {
  return useMutation({
    mutationFn: async (fileList: FileList) => {
      const IMAGE_SIZE_MB = 10 * 1024 * 1024;
      const TOTAL_SIZE_MB = 100 * 1024 * 1024;
      const ALLOWED_EXTENSIONS = ["png", "jpg", "jpeg"];
      let totalSize = 0;

      const formData = new FormData();

      for (const file of Array.from(fileList)) {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!fileExtension || !ALLOWED_EXTENSIONS.includes(fileExtension)) {
          Toast.error(`png, jpg, jpeg 확장자만 가능합니다.`);
          throw new Error(`허용되지 않는 확장자입니다.`);
        }
        if (file.size > IMAGE_SIZE_MB) {
          Toast.error(`파일 크기가 10MB를 초과합니다.`);
          throw new Error(`파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }

        totalSize += file.size;
        if (totalSize > TOTAL_SIZE_MB) {
          Toast.error(`총 파일 크기가 100MB를 초과합니다.`);
          throw new Error("총 파일 크기가 100MB를 초과합니다.");
        }

        const resizeFile = await getImgUpload(file);

        formData.append("files", resizeFile);
      }

      return uploadImage(formData);
    },
  });
};

// 영상 파일 업로드
const uploadVideo = async (formData: FormData): Promise<UploadResponse> => {
  return await requestToCastingVote
    .post("/upload/video", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

const useUploadVideo = () => {
  return useMutation({
    mutationFn: (fileList: FileList) => {
      const VIDEO_SIZE_MB = 50 * 1024 * 1024;
      const TOTAL_SIZE_MB = 100 * 1024 * 1024;
      const ALLOWED_EXTENSIONS = ["mp4", "mov", "avi", "webp"];
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!fileExtension || !ALLOWED_EXTENSIONS.includes(fileExtension)) {
          Toast.error(`mp4, mov, avi 확장자만 가능합니다.`);
          throw new Error(`허용되지 않는 확장자입니다.`);
        }

        if (file.size > VIDEO_SIZE_MB) {
          Toast.error(`파일 크기가 50MB를 초과합니다.`);
          throw new Error(`영상 파일 ${file.name}의 크기가 50MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_SIZE_MB) {
        Toast.error(`파일 크기가 100MB를 초과합니다.`);
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadVideo(formData);
    },
  });
};

// 음성 파일 업로드
const uploadAudio = async (formData: FormData): Promise<UploadResponse> => {
  return await requestToCastingVote
    .post("/upload/audio", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => res.data);
};

const useUploadAudio = () => {
  return useMutation({
    mutationFn: (fileList: FileList) => {
      const AUDIO_SIZE_MB = 10 * 1024 * 1024;
      const TOTAL_SIZE_MB = 100 * 1024 * 1024;
      let totalSize = 0;

      for (const file of Array.from(fileList)) {
        if (file.size > AUDIO_SIZE_MB) {
          Toast.error(`파일 크기가 10MB를 초과합니다.`);
          throw new Error(`음성 파일 ${file.name}의 크기가 10MB를 초과합니다.`);
        }
        totalSize += file.size;
      }

      if (totalSize > TOTAL_SIZE_MB) {
        Toast.error(`파일 크기가 100MB를 초과합니다.`);
        throw new Error("총 파일 크기가 100MB를 초과합니다.");
      }

      const formData = new FormData();
      Array.from(fileList).forEach((file) => {
        formData.append("files", file);
      });

      return uploadAudio(formData);
    },
  });
};

export { useUploadFile, useUploadImage, useUploadVideo, useUploadAudio };

export interface UploadResponse {
  code: string;
  status: string;
  message: string;
  data: Array<{
    attachmentId: number;
    attachmentUrl: string;
  }>;
}
