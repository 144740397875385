import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import {
  changeProductionRequest,
  productionsRequest,
} from "types/production";
import { createProduct, updateProduct } from "types/product/product";

// 작품 등록
const createProduction = async (createProductionRequest: createProduct) => {
  return await requestToCastingVote
    .post(`productions`, createProductionRequest)
    .then((res) => res.data);
};

const useCreateProduction = () => {
  return useMutation({
    mutationFn: createProduction,
  });
};

// 작품 수정
const updateProduction = async (updateProductionRequest: updateProduct) => {
  return await requestToCastingVote
    .patch(`productions `, updateProductionRequest)
    .then((res) => res.data);
};

const useUpdateProduction = () => {
  return useMutation({
    mutationFn: updateProduction,
  });
};

// 작품 상태 변경
const changeCastingcallStatus = async (
  changeProductionRequest: changeProductionRequest
) => {
  return await requestToCastingVote
    .patch(`productions/status`, changeProductionRequest)
    .then((res) => res.data);
};

const useChangeProductionStatus = () => {
  return useMutation({
    mutationFn: changeCastingcallStatus,
  });
};

// 작품 삭제
const deleteProduction = async (productionId: number) => {
  return await requestToCastingVote
    .delete(`productions`, { data: productionId })
    .then((res) => res.data);
};

const useDeleteProduction = () => {
  return useMutation({
    mutationFn: deleteProduction,
  });
};

// 작품 목록 조회
const getProductions = async (productionsRequest: productionsRequest) => {
  return await requestToCastingVote
    .get(`productions`, { params: productionsRequest })
    .then((res) => res.data);
};

const useProductions = (productionsRequest: productionsRequest) => {
  return useInfiniteQuery({
    queryKey: ["productions", productionsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getProductions({ ...productionsRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 작품 상세 조회
const getProduction = async (id: number) => {
  return await requestToCastingVote
    .get(`productions/${id}`)
    .then((res) => res.data);
};

const useProduction = (id: number) => {
  return useQuery({
    queryKey: ["production", id],
    queryFn: () => getProduction(id),
  });
};

export {
  useCreateProduction,
  useUpdateProduction,
  useChangeProductionStatus,
  useDeleteProduction,
  useProductions,
  useProduction,
};
