import React, { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useInView } from "react-intersection-observer";
import useLocalStorage from "use-local-storage";
import { useScroll } from "hooks/useScroll";
import ProductionCard from "./MyCasting/_components/ProductionCard";
import { useMyProductions } from "apis/my";

const StaffMyCasting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollKey = `scrollIndex${location.pathname}`;


  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  const { y } = useScroll();

  useEffect(() => {
    if (scrollY !== 0) window.scrollTo(0, scrollY);
  }, []);

  const { ref, inView } = useInView({
    threshold: 0.5,
  });
  const {
    data,
    fetchNextPage,
    hasNextPage,
  } = useMyProductions({ isActiveProfile: true });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const allLists = useMemo(
    () => (data ? data?.pages?.flatMap((page) => page.data.content) : []),
    [data]
  );

  return (
    <div>
      <HeaderWithBackButton title="내 캐스팅">
        <div
          onClick={() => {
            navigate("/staff/post");
          }}
          className={`BCaption14 text-Blue04 flex items-center gap-2 cursor-pointer`}
        >
          <div>작품등록</div>
        </div>
      </HeaderWithBackButton>
      <div className="pb-24">
        <div className="flex flex-col">
          {allLists.map((prodItem: myProduct) => {
            return (
              <ProductionCard
                key={prodItem.productionId}
                thumbnailUrl={prodItem.thumbnailUrl}
                format={prodItem.format.label}
                productionId={prodItem.productionId}
                companyName={prodItem.companyName}
                title={prodItem.title} />
            );
          })}
          {hasNextPage && <div className="h-5 bg-transparent" ref={ref} />}
        </div>
      </div>
    </div>
  );
};

export default StaffMyCasting;


interface myProduct {
  productionId: number;
  title: string;
  format: {
    code: string;
    label: string;
  },
  companyName: string;
  thumbnailUrl: string;
}