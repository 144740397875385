import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import requestToCastingVote from "../utils/requestToCastingVote";
import useLoginInfo from "stores/loginInfo";
import { Toast } from "hooks/useToast";

const KakaoLoginSuccess = () => {
  const navigate = useNavigate();
  const { setIsLogin, setLoginInfo } = useLoginInfo();

  useEffect(() => {
    requestToCastingVote({
      method: "GET",
      url: "/auth",
    })
      .then((res) => {
        navigate("/", { replace: true });
        setIsLogin(true);
        setLoginInfo()
      })
      .catch((error) => {
        Toast.error("오류 발생", error);
      });
  }, []);

  return <div></div>;
};

export default KakaoLoginSuccess;
