import { useMutation, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";

import {
  certVerifyRequest,
  changePasswordRequest,
  findUserIdRequest,
  kakaoLinkRequest,
  kakaoSignupRequest,
  loginRequest,
  signupRequest,
} from "types/user";

// 회원가입
const signup = async (signupRequest: signupRequest) => {
  return await requestToCastingVote
    .post(`auth/signup`, signupRequest)
    .then((res) => res.data);
};

const useSignup = () => {
  return useMutation({
    mutationFn: signup,
  });
};

// 로그인
const login = async (loginRequest: loginRequest) => {
  return await requestToCastingVote
    .post(`auth/login`, loginRequest)
    .then((res) => res.data);
};

const useLogin = () => {
  return useMutation({
    mutationFn: login,
  });
};

// 로그아웃
const logout = async () => {
  return await requestToCastingVote.post(`auth/logout`).then((res) => res.data);
};

const useLogout = () => {
  return useMutation({
    mutationFn: logout,
    onSuccess: () => {
      localStorage.clear();
      window.location.replace("/login");
    },
  });
};

// 로그인 정보 가져오기
const getLoginInfo = async () => {
  return await requestToCastingVote.get(`auth/info`).then((res) => res.data);
};

const useLoginInfo = () => {
  return useQuery({
    queryKey: ["loginInfo"],
    queryFn: getLoginInfo,
  });
};

// 아이디 찾기
const findUserId = async (findUserIdRequest: findUserIdRequest) => {
  return await requestToCastingVote
    .post(`auth/find/userId`, findUserIdRequest)
    .then((res) => res.data);
};

const useFindUserId = () => {
  return useMutation({
    mutationFn: findUserId,
  });
};

// 비밀번호 변경하기
const changePassword = async (changePasswordRequest: changePasswordRequest) => {
  return await requestToCastingVote
    .post(`auth/change/password`, changePasswordRequest)
    .then((res) => res.data);
};

const useChangePassword = () => {
  return useMutation({
    mutationFn: changePassword,
  });
};

// 인증 번호 요청
const requestCert = async (phoneNumber: string) => {
  return await requestToCastingVote
    .post(`cert/request`, { phoneNumber })
    .then((res) => res.data);
};

const useRequestCert = () => {
  return useMutation({
    mutationFn: requestCert,
  });
};

// 인증 번호 검증
const certVerify = async (certVerifyRequest: certVerifyRequest) => {
  return await requestToCastingVote
    .post(`cert/verify`, certVerifyRequest)
    .then((res) => res.data);
};

const useCertVerify = () => {
  return useMutation({
    mutationFn: certVerify,
  });
};

// 카카오로 회원가입
const kakaoSignup = async (kakaoSignupRequest: kakaoSignupRequest) => {
  return await requestToCastingVote
    .post(`auth/kakao/signup`, kakaoSignupRequest)
    .then((res) => res.data);
};

const useKakaoSignup = () => {
  return useMutation({
    mutationFn: kakaoSignup,
  });
};

// 카카오로 계정 연동
const kakaoLink = async (kakaoLinkRequest: kakaoLinkRequest) => {
  return await requestToCastingVote
    .post(`auth/kakao/link`, kakaoLinkRequest)
    .then((res) => res.data);
};

const useKakaoLink = () => {
  return useMutation({
    mutationFn: kakaoLink,
  });
};

export {
  signup,
  useSignup,
  login,
  useLogin,
  logout,
  useLogout,
  getLoginInfo,
  useLoginInfo,
  findUserId,
  useFindUserId,
  changePassword,
  useChangePassword,
  requestCert,
  useRequestCert,
  certVerify,
  useCertVerify,
  useKakaoSignup,
  useKakaoLink,
};
