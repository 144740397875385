import { useInfiniteQuery, useMutation } from "@tanstack/react-query";

import requestToCastingVote from "./api";

import {
  changeMemberInfoRequest,
  membersRequest,
  staffsRequest,
} from "types/member";

// 회원 정보 수정
const changeMemberInfo = async (
  changeMemberInfoRequest: changeMemberInfoRequest
) => {
  return await requestToCastingVote
    .patch(`members`, changeMemberInfoRequest )
    .then((res) => res.data);
};

const useChangeMemberInfo = () => {
  return useMutation({
    mutationFn: changeMemberInfo,
  });
};

// 회원 탈퇴
const deleteMember = async (reasonForWithdrawal?: string) => {
  return await requestToCastingVote
    .delete(`members`, { data: {reasonForWithdrawal} })
    .then((res) => res.data);
};

const useDeleteMember = () => {
  return useMutation({
    mutationFn: deleteMember,
  });
};

// 회원 목록 조회
const getMembers = async (membersRequest: membersRequest) => {
  return await requestToCastingVote
    .get(`members`, { params: membersRequest })
    .then((res) => res.data);
};

const useMembers = (membersRequest: membersRequest) => {
  return useInfiniteQuery({
    queryKey: ["members", membersRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMembers({ ...membersRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 스태프 목록 조회
const getStaffs = async (staffsRequest: staffsRequest) => {
  return await requestToCastingVote
    .get(`members/staff`, { params: staffsRequest })
    .then((res) => res.data);
};

const useStaffs = (staffsRequest: staffsRequest) => {
  return useInfiniteQuery({
    queryKey: ["members", staffsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getStaffs({ ...staffsRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

export {
  changeMemberInfo,
  useChangeMemberInfo,
  deleteMember,
  useDeleteMember,
  getMembers,
  useMembers,
  getStaffs,
  useStaffs,
};
