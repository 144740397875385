import React, { useEffect, useMemo } from "react";

import StaffWorkCard from "components/StaffWorkCard";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router";
import { useMyProductions } from "apis/my";

interface StaffMyProductionsProps {
  isActive: boolean;
}

const StaffMyProductions = ({ isActive }: StaffMyProductionsProps) => {
  const navigate = useNavigate();
  const { data, fetchNextPage, hasNextPage } = useMyProductions({
    isActiveProfile: isActive,
  });

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const myProducts = useMemo(
    () => (data ? data?.pages?.flatMap((data) => data.data.content) : []),
    [data]
  );

  return (
    <>
      {myProducts.length === 0 ? (
        <div className="mt-36 items-center text-center">
          <div className="text-Gray05 RBody14">등록된 작품이 없어요</div>
        </div>
      ) : (
        <>
          <div className="gap-x-4 gap-y-4 grid grid-cols-2">
            {myProducts.map((prodItem) => {
              return (
                <StaffWorkCard
                  isCasting={prodItem?.isCasting}
                  key={prodItem.productionId}
                  onClick={() =>
                    navigate(`/staff/filmo/${prodItem.productionId}`)
                  }
                  title={prodItem.title}
                  src={prodItem.thumbnailUrl}
                  isFavorite={prodItem.isFavorite}
                />
              );
            })}
          </div>
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </>
      )}
    </>
  );
};

export default StaffMyProductions;
