import React from "react";
import Logo from "../assets/CastingVote_logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { TintButton } from "./TintButton";
import useLoginInfo from "stores/loginInfo";

const Header = ({ title }: { title?: string }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLogin } = useLoginInfo();


  return (
    <div className="relative w-full left-0 top-0 z-50">
      <div className="flex px-5 w-full justify-between h-12 mx-auto items-center">
        {title ? (
          <div className="BBody20 text-Gray09">{title}</div>
        ) : (
          <img
            onClick={() => {
              navigate("/");
            }}
            className="w-[76px] h-7 cursor-pointer"
            src={Logo}
            alt="로고"
          />
        )}
        {!isLogin && location.pathname !== "/login" && (
          <TintButton
            onClick={() => {
              navigate("/login");
            }}
            variant="normal"
            size="S"
            text="로그인"
          />
        )}
      </div>
    </div>
  );
};

export default Header;
