import ButtonBackground from "components/ButtonBackground";
import { FillButton } from "components/FillButton";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { LineButton } from "components/LineButton";
import { useLocation, useNavigate } from "react-router";
import KakaoLogin from "../assets/kakaoLogin.png";

const MemberFindId = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const { state } = useLocation();

  const result = state?.filter((id: string) => id?.startsWith("kakao_"));

  const handleKakaoLogin = () => {
    // window.location.href = `http://localhost:8080/api/oauth2/authorization/kakao`;
    window.location.href = `${API_URL}/oauth2/authorization/kakao`;
  };

  return (
    <div>
      <HeaderWithBackButton title={"아이디 찾기"} />
      <div className="px-5">
        {result?.length ? (
          <div>
            <div className="py-[100px] mx-auto text-center flex gap-10 flex-col max-w-[192px] justify-center items-center">
              <div className="RBody16 text-Gray05">
                카카오 간편 로그인으로 가입한 내역이 있어요.
              </div>
              <div className="text-sm RBody14 cursor-pointer grid gap-2 text-Gray09 items-center mx-auto">
                <img
                  src={KakaoLogin}
                  alt="snsLogin"
                  onClick={handleKakaoLogin}
                />
                <div>카카오톡</div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="py-10">
              <div className="text-Gray05 RBody16">
                인증한 휴대폰 번호로 가입된 아이디예요.
              </div>
            </div>
            <div className="w-full bg-Gray01 py-3 text-center text-Gray09 MBody16 rounded-md">
              {state?.length !== 0 ? state?.join() : "가입된 계정이 없습니다."}
            </div>
            <ButtonBackground>
              <LineButton
                className="w-full"
                variant="normal"
                size="L"
                onClick={() => {
                  navigate("/find/verify", {
                    state: "PASSWORD",
                  });
                }}
                text="비밀번호 찾기"
              ></LineButton>
              <FillButton
                className="w-full"
                onClick={() => {
                  navigate("/login");
                }}
                variant="Membership"
                size="L"
                text="로그인하기"
              ></FillButton>
            </ButtonBackground>
          </>
        )}
      </div>
    </div>
  );
};

export default MemberFindId;
