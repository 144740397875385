import React from "react";

import Footer from "./Footer";
import { Outlet, useNavigate } from "react-router-dom";
import TabBar from "./TabBar";
import axios from "axios";
import requestToCastingVote from "apis/api";


const Layout = () => {
  const navigate = useNavigate();

  requestToCastingVote.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (!originalRequest?._retry) {
        originalRequest._retry = true;

        switch (error.response?.data.code) {
          case "ERR4001":
            localStorage.clear();
            navigate("/login?code=4001", { replace: true });
            return axios(originalRequest);
          case "ERR4003":
            navigate("/", { replace: true });
            break;
          case "ERR4004":
            navigate("/membership", { replace: true });
            break;
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      <div className="w-full min-h-full bg-Gray00 shadow-[0_0px_20px_rgba(0,0,0,0.1)] relative min-w-[280px] max-w-[400px] mx-auto">
        <Outlet />
        <TabBar />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
