import React, { useState } from 'react';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import { useLocation, useNavigate } from 'react-router';
import { useOffer } from 'apis/offer';
import { Toast } from 'hooks/useToast';

const SuggestDeadline = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [deadlineDate, setDeadlineDate] = useState<null | string>("");

    const isDateValid = (date: string) => {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        return regex.test(date);
    };

    const { memberId, profileId, description } = location?.state;


    const mutation = useOffer();

    const suggest = () => {
        mutation.mutate(
            {
                artistId: memberId,
                profileId: profileId,
                castingCallId: null,
                description: description,
                deadlineDate: null,
            },
            {
                onSuccess: () => {
                    Toast.success(`아티스트에게 제안을 보냈어요.`);
                    navigate(`/artist/${profileId}`, {
                        replace: true,
                    });
                },
            }
        );
    };


    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                언제까지 유효한 제안인가요?
            </div>
            <div className='px-5'>
                <div className="RCaption14 mt-2 mb-4 text-Gray05">
                    설정하신 날짜가 지나면 자동으로 취소돼요.
                </div>
                <Input
                    disabled={deadlineDate === null}
                    value={deadlineDate!}
                    onChange={(e) => {
                        const inputValue = e.target.value.replace(/\D/g, "");

                        let formattedDate = inputValue;
                        if (inputValue.length > 4) {
                            formattedDate =
                                inputValue.slice(0, 4) + "-" + inputValue.slice(4);
                        }
                        if (inputValue.length > 6) {
                            formattedDate =
                                inputValue.slice(0, 4) +
                                "-" +
                                inputValue.slice(4, 6) +
                                "-" +
                                inputValue.slice(6, 8);
                        }

                        setDeadlineDate(formattedDate);
                    }}
                    type={"text"}
                    placeholder="YYYY-MM-DD 형식으로 입력해주세요"
                />
                <div className="mt-4">
                    <Checkbox
                        onChange={(e) => {
                            const checked = e.target.checked;
                            if (checked) {
                                setDeadlineDate(null);
                            } else {
                                setDeadlineDate("");
                            }
                        }}
                        title={"아무때나 좋아요"}
                        id="anyTime"
                    />
                </div>
            </div>
            <ButtonBackground>
                <FillButton
                    className='w-full'
                    disabled={
                        deadlineDate !== null ? !isDateValid(deadlineDate!) : false
                    }
                    onClick={() => {
                        suggest()
                    }}
                    variant="Membership"
                    size="B"
                    text='제안하기'
                />
            </ButtonBackground>
        </div>
    );
};

export default SuggestDeadline;