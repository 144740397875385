import { useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";

// 피드백 현황
const feedbackStatistics = async (monthsAgo: number) => {
  return await requestToCastingVote
    .get(`statistics/feedback`, { params: { monthsAgo } })
    .then((res) => res.data);
};

const useFeedbackStatistics = (monthsAgo: number) => {
  return useQuery({
    queryKey: ["feedbackStatistics", monthsAgo],
    enabled: !!monthsAgo,
    queryFn: () => feedbackStatistics(monthsAgo),
  });
};

export { useFeedbackStatistics };
