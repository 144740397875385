import React from "react";
import { useNavigate } from "react-router-dom";

import NoProfile from "../assets/noProfile.png";

import Button from "components/Button";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { JOB_LABELS } from "components/SelectBox";
import { LineButton } from "components/LineButton";

import { MY_ROLE, MEMBER_APPROVED } from "utils/onRoleStatus";
import StaffDescCard from "./StaffDetail/_components/StaffDescCard";
import StaffMyProductions from "./StaffMy/StaffMyProductions";
import StaffMyArtistProfiles from "./StaffMy/StaffMyArtistProfiles";

import { useMyProfile } from "apis/my";

const StaffMy = () => {
  const role = MY_ROLE();
  const isApproved = MEMBER_APPROVED();
  const navigate = useNavigate();

  const { data } = useMyProfile();
  const StaffData = data?.data;

  const isManager = JOB_LABELS[role!] === "매니저";

  return (
    <div>
      <HeaderWithBackButton title="내 프로필" />
      {StaffData === null ? (
        <div className="text-center mx-auto px-5 flex-col pb-24">
          <img
            className="w-[60px] h-[60px] mx-auto"
            src={NoProfile}
            alt="NoProfile"
          />
          <div className="mt-4 mb-10 MBody16 text-Gray05 whitespace-normal">
            아직 프로필이 없어요.
            <br />
            프로필을 등록하면 캐스팅을 시작할 수 있어요!
          </div>
          <Button
            className="px-4 py-3 text-Gray00 bg-Blue04 border-none"
            text={"프로필 등록하기"}
            onClick={() => {
              navigate("/staff/profile");
            }}
          />
        </div>
      ) : (
        <div className="flex p-5 pb-24 flex-col">
          <StaffDescCard
            staffName={StaffData?.profileName!}
            imageUrl={StaffData?.thumbnailUrl!}
            staffRole={JOB_LABELS[role!]}
            staffAgency={StaffData?.basicInfo?.agency!}
            filmography={StaffData?.filmographies!}
            filmographySummary={StaffData?.filmographySummary!}
          />
          <LineButton
            className="mt-4"
            onClick={() => navigate("/staff/profile/edit")}
            variant="normal"
            size="M"
            text="프로필 수정"
          />
          <div className="pt-4">
            {isManager ? (
              <StaffMyArtistProfiles />
            ) : (
              <StaffMyProductions isActive={isApproved} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StaffMy;
