import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import {
  myCastingcallsByProductionRequest,
  myCastingcallsRequest,
  myProductionsRequest,
  myRelatedCastingcallsRequest,
} from "types/my";

// 내 정보
const getMyInfo = async () => {
  return await requestToCastingVote.get(`mypage/info`).then((res) => res.data);
};

const useMyInfo = () => {
  return useQuery({
    queryKey: ["myInfo"],
    queryFn: () => getMyInfo(),
  });
};

// 내 프로필
const getMyProfile = async () => {
  return await requestToCastingVote
    .get(`mypage/profile`)
    .then((res) => res.data);
};

const useMyProfile = () => {
  return useQuery({
    queryKey: ["myProfile"],
    queryFn: () => getMyProfile(),
  });
};

// 스태프 내 작품 목록
const getMyProductions = async (myProductionsRequest: myProductionsRequest) => {
  return await requestToCastingVote
    .get(`mypage/productions`, {
      params: myProductionsRequest,
    })
    .then((res) => res.data);
};

const useMyProductions = (myProductionsRequest: myProductionsRequest) => {
  return useInfiniteQuery({
    queryKey: ["myProductions", myProductionsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMyProductions({
        ...myProductionsRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    enabled: !!myProductionsRequest.isActiveProfile,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 스태프 내 작품 상세
const getMyProduction = async (id: number) => {
  return await requestToCastingVote
    .get(`mypage/productions/${id}`)
    .then((res) => res.data);
};

const useMyProduction = (id: number) => {
  return useQuery({
    queryKey: ["myProduction", id],
    queryFn: () => getMyProduction(id),
  });
};

// 스태프 내 공고 목록
const getMyCastingcalls = async (
  myCastingcallsRequest: myCastingcallsRequest
) => {
  return await requestToCastingVote
    .get(`mypage/castingcalls`, {
      params: myCastingcallsRequest,
    })
    .then((res) => res.data);
};

const useMyCastingcalls = (myCastingcallsRequest: myCastingcallsRequest) => {
  return useInfiniteQuery({
    queryKey: ["myCastingcalls", myCastingcallsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMyCastingcalls({
        ...myCastingcallsRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 스태프 내 공고 상세
const getMyCastingcall = async (id: number) => {
  return await requestToCastingVote
    .get(`mypage/castingcalls/${id}`)
    .then((res) => res.data);
};

const useMyCastingcall = (id: number) => {
  return useQuery({
    queryKey: ["myCastingcall", id],
    queryFn: () => getMyCastingcall(id),
  });
};

// 스태프 내 작품별 공고 목록
const getMyCastingcallsByProduction = async (
  myCastingcallsByProductionRequest: myCastingcallsByProductionRequest
) => {
  return await requestToCastingVote
    .get(
      `mypage/productions/${myCastingcallsByProductionRequest.id}/castingcalls`,
      {
        params: myCastingcallsByProductionRequest,
      }
    )
    .then((res) => res.data);
};

const useMyCastingcallsByProduction = (
  myCastingcallsByProductionRequest: myCastingcallsByProductionRequest
) => {
  return useInfiniteQuery({
    queryKey: ["castingcallsByProduction", myCastingcallsByProductionRequest],
    queryFn: ({ pageParam = 0 }) =>
      getMyCastingcallsByProduction({
        ...myCastingcallsByProductionRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    enabled: !!myCastingcallsByProductionRequest.id,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 스태프 내 관계자로 추가된 공고 목록
const getRelatedCastingcalls = async (
  myRelatedCastingcallsRequest: myRelatedCastingcallsRequest
) => {
  return await requestToCastingVote
    .get(`mypage/castingcalls/stakeholder`, {
      params: myRelatedCastingcallsRequest,
    })
    .then((res) => res.data);
};

const useRelatedCastingcalls = (
  myRelatedCastingcallsRequest: myRelatedCastingcallsRequest
) => {
  return useInfiniteQuery({
    queryKey: ["myRelatedCastingcalls", myRelatedCastingcallsRequest],
    queryFn: ({ pageParam = 0 }) =>
      getRelatedCastingcalls({
        ...myRelatedCastingcallsRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

export {
  useMyInfo,
  useMyProfile,
  useMyProduction,
  useMyProductions,
  useMyCastingcall,
  useMyCastingcalls,
  useMyCastingcallsByProduction,
  useRelatedCastingcalls,
};
