import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import { pointHistoryRequest } from "types/point";

// 포인트 조회
const getPoint = async () => {
  return await requestToCastingVote
    .get(`memberpoint`)
    .then((res) => res.data);
};

const usePoint = () => {
  return useQuery({
    queryKey: ["point"],
    queryFn: () => getPoint(),
  });
};

// 남은 포인트 체크
const getPointCheck = async (point: number) => {
  return await requestToCastingVote
    .get(`memberpoint/check`, { params: { point } })
    .then((res) => res.data);
};

const usePointCheck = (point: number) => {
  return useQuery({
    queryKey: ["pointCheck", point],
    queryFn: () => getPointCheck(point),
    enabled: point !== 0,
  });
};

// 포인트 사용 내역 조회
const getPointHistory = async (pointHistoryRequest: pointHistoryRequest) => {
  return await requestToCastingVote
    .get(`points/histories`, { params: pointHistoryRequest })
    .then((res) => res.data);
};

const usePointHistory = (pointHistoryRequest: pointHistoryRequest) => {
  return useInfiniteQuery({
    queryKey: ["pointHistory", pointHistoryRequest],
    queryFn: ({ pageParam = 0 }) =>
      getPointHistory({ ...pointHistoryRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 전체 스탭 유형별 포인트
const getRolePoint = async () => {
  return await requestToCastingVote
    .get(`role/points`)
    .then((res) => res.data);
};

const useRolePoint = () => {
  return useQuery({
    queryKey: ["rolePoint"],
    queryFn: () => getRolePoint(),
  });
};

// 스탭 유형, 프로필 보내기 유형별 포인트 조회
const getPointCheckByRole = async (
  pointHistoryRequest: pointHistoryRequest
) => {
  return await requestToCastingVote
    .get(`role/point`, { params: pointHistoryRequest })
    .then((res) => res.data)
};

const usePointCheckByRole = (pointHistoryRequest: pointHistoryRequest) => {
  return useQuery({
    queryKey: ["pointCheckByRole", pointHistoryRequest],
    queryFn: () => getPointCheckByRole(pointHistoryRequest),
    enabled: !!pointHistoryRequest?.role && !!pointHistoryRequest?.type,
  });
};

export {
  usePoint,
  usePointCheck,
  usePointHistory,
  useRolePoint,
  usePointCheckByRole,
};
