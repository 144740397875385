import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";

import requestToCastingVote from "./api";
import {
  deliveryProfileToStaffRequest,
  deliveryProfileToCastingRequest,
  cancelDeliveryProfileRequest,
  sentProfilesRequest,
  receivedProfilesRequest,
  checkAlreadySendProfileRequest,
  feedbackReceivedProfileRequest,
} from "types/deliveryProfile";

// 스탭에게 프로필 보내기
const deliveryProfileToStaff = async (
  deliveryProfileToStaffRequest: deliveryProfileToStaffRequest
) => {
  return await requestToCastingVote
    .post(`delivery-profiles`, deliveryProfileToStaffRequest)
    .then((res) => res.data);
};

const useDeliveryProfileToStaff = () => {
  return useMutation({
    mutationFn: deliveryProfileToStaff,
  });
};

// 공고로 프로필 보내기
const deliveryProfileToCasting = async (
  deliveryProfileToCastingRequest: deliveryProfileToCastingRequest
) => {
  return await requestToCastingVote
    .post(`delivery-profiles/cast`, deliveryProfileToCastingRequest)
    .then((res) => res.data);
};

const useDelveryProfileToCasting = () => {
  return useMutation({
    mutationFn: deliveryProfileToCasting,
  });
};

// 보낸 프로필 취소
const cancelDeliveryProfile = async (
  id: number | null,
  cancelDeliveryProfileRequest?: cancelDeliveryProfileRequest
) => {
  return await requestToCastingVote
    .patch(`delivery-profiles/${id}/cancel`, cancelDeliveryProfileRequest)
    .then((res) => res.data);
};

const useCancelDeliveryProfileRequest = () => {
  return useMutation({
    mutationFn: cancelDeliveryProfile,
  });
};

// 보낸 프로필 목록 조회
const getSentProfiles = async (sentProfilesRequest: sentProfilesRequest) => {
  return await requestToCastingVote
    .get(`delivery-profiles/sent`, {
      params: sentProfilesRequest,
    })
    .then((res) => res.data);
};

const useSentProfiles = (sentProfilesRequest: sentProfilesRequest) => {
  return useInfiniteQuery({
    queryKey: ["sentProfiles", sentProfilesRequest],
    queryFn: ({ pageParam = 0 }) =>
      getSentProfiles({
        ...sentProfilesRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 보낸 프로필 상세 조회
const getSentProfile = async (id: number) => {
  return await requestToCastingVote
    .get(`delivery-profiles/${id}/sent`)
    .then((res) => res.data);
};

const useSentProfile = (id: number) => {
  return useQuery({
    queryKey: ["sentProfile", id],
    enabled: !!id,
    queryFn: () => getSentProfile(id),
  });
};

// 받은 프로필 목록 조회
const getReceivedProfiles = async (
  receivedProfilesRequest: receivedProfilesRequest
) => {
  return await requestToCastingVote
    .get(`delivery-profiles/received`, {
      params: receivedProfilesRequest,
    })
    .then((res) => res.data);
};

const useReceivedProfiles = (
  receivedProfilesRequest: receivedProfilesRequest
) => {
  return useInfiniteQuery({
    queryKey: ["receivedProfiles", receivedProfilesRequest],
    queryFn: ({ pageParam = 0 }) =>
      getReceivedProfiles({
        ...receivedProfilesRequest,
        page: pageParam,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 받은 프로필 상세 조회
const getReceivedProfile = async (id: number) => {
  return await requestToCastingVote
    .get(`delivery-profiles/${id}/received`)
    .then((res) => res.data);
};

const useReceivedProfile = (id: number) => {
  return useQuery({
    queryKey: ["receivedProfile", id],
    enabled: !!id,
    queryFn: () => getReceivedProfile(id),
  });
};

// 받은 프로필 읽기
const readReceivedProfile = async (id: number) => {
  return await requestToCastingVote
    .patch(`delivery-profiles/${id}/read`)
    .then((res) => res.data);
};

const useReadReceivedProfile = () => {
  return useMutation({
    mutationFn: readReceivedProfile,
  });
};

// 받은 프로필 숨기기
const hideReceivedProfile = async (id: number) => {
  return await requestToCastingVote
    .patch(`delivery-profiles/${id}/hide`)
    .then((res) => res.data);
};

const useHideReceivedProfile = () => {
  return useMutation({
    mutationFn: hideReceivedProfile,
  });
};

// 받은 프로필 피드백
const feedbackReceivedProfile = async (
  feedbackReceivedProfileRequest: feedbackReceivedProfileRequest
) => {
  return await requestToCastingVote
    .patch(`delivery-profiles/${feedbackReceivedProfileRequest?.id}/feedback`, {
      rating: feedbackReceivedProfileRequest.rating,
      comments: feedbackReceivedProfileRequest.comments,
    })
    .then((res) => res.data);
};

const useFeedbackReceivedProfile = () => {
  return useMutation({
    mutationFn: feedbackReceivedProfile,
  });
};

// 피드백 읽기
const readFeedback = async (id: number) => {
  return await requestToCastingVote
    .patch(`delivery-profiles/${id}/feedback/read`)
    .then((res) => res.data);
};

const useReadFeedback = () => {
  return useMutation({
    mutationFn: readFeedback,
  });
};

// 프로필 보내기 체크
const checkAlreadySentProfile = async (
  checkAlreadySendProfileRequest: checkAlreadySendProfileRequest
) => {
  return await requestToCastingVote
    .get(`delivery-profiles/check`, { params: checkAlreadySendProfileRequest })
    .then((res) => res.data);
};

const useCheckAlreadySentProfile = (
  checkAlreadySendProfileRequest: checkAlreadySendProfileRequest
) => {
  return useQuery({
    queryKey: ["alreadySentProfile", checkAlreadySendProfileRequest],
    queryFn: () => checkAlreadySentProfile(checkAlreadySendProfileRequest),
  });
};

export {
  useDeliveryProfileToStaff,
  useDelveryProfileToCasting,
  useCancelDeliveryProfileRequest,
  useSentProfiles,
  useSentProfile,
  useReceivedProfiles,
  useReceivedProfile,
  useReadReceivedProfile,
  useHideReceivedProfile,
  useFeedbackReceivedProfile,
  useReadFeedback,
  useCheckAlreadySentProfile,
};
