import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useInView } from "react-intersection-observer";

import HeaderWithBackButton from "components/HeaderWithBackButton";

import { formatDate } from "utils/onDateFormat";

import { useMembershipHistory } from "apis/payment";

const MembershipHistroy = () => {
  const { state } = useLocation();
  const { data, fetchNextPage, hasNextPage } = useMembershipHistory({});

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);

  const history = data?.pages.flatMap((item: any) => item.data.content) || [];

  return (
    <div>
      <HeaderWithBackButton title={"결제 내역"} />
      <div className="pt-10 pb-24">
        <div className="px-5">
          <div className="flex items-center justify-between">
            <div>
              <div>이용중인 멤버십</div>
              <div className="mt-2 mb-1">{state.membershipName}</div>
              <div>{state.period}</div>
            </div>
            {/*<div>{state.price}원</div>*/}
          </div>
        </div>
        <hr className="h-3 my-4 bg-Gray01" />
        <div className="px-5">
          {history.map((item, i) => {
            return (
              <React.Fragment key={i}>
                <div className="flex items-center justify-between">
                  <div>
                    <div>{formatDate(item?.createdAt)}</div>
                    <div>{item?.cardName}</div>
                  </div>
                  <div>{item?.totalPrice?.toLocaleString("ko-KR")}원</div>
                </div>

                <hr className="last:hidden w-full bg-Gray01 my-4" />
              </React.Fragment>
            );
          })}
          {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
        </div>
      </div>
    </div>
  );
};

export default MembershipHistroy;
