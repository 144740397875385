import React, { useState } from 'react';

import { useOffer } from 'apis/offer';
import ButtonBackground from 'components/ButtonBackground';
import { FillButton } from 'components/FillButton';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import Textarea from 'components/Textarea';
import { Toast } from 'hooks/useToast';
import { useLocation, useNavigate } from 'react-router-dom';

const SuggestDescription = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [suggestDescription, setSuggestDescription] = useState<string>('');

    const pageData = location?.state;

    const { artistName, memberId, profileId, productId, castingCallId } = pageData;

    const { mutate } = useOffer();

    const suggest = () => {
        mutate(
            {
                artistId: memberId,
                profileId: profileId,
                castingCallId: castingCallId,
                description: suggestDescription,
                deadlineDate: null,
            },
            {
                onSuccess: () => {
                    Toast.success(`아티스트에게 제안을 보냈어요.`);
                    navigate(`/artist/${profileId}`, {
                        replace: true,
                    });
                },
            }
        );
    };

    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                제안할 내용을 입력해주세요
            </div>
            <div className='px-5 pt-5'>
                <Textarea
                    placeholder={
                        "안녕하세요 아티스트님! 저희가 찾고 있는 이미지와 일치하여 지금 모집중인 [작품명]의 [배역] 제안드립니다!\n\n보내드린 캐스팅 공고 확인 부탁드립니다 :)\n감사합니다."
                    }
                    value={suggestDescription}
                    onChange={(e) => {
                        setSuggestDescription(e.target.value);
                    }}
                    className="w-full h-[248px]"
                    maxlength={500}
                ></Textarea>
                <ul className="RBody14 text-Gray05">
                    <li>
                        • {artistName}님이 관심 가질 수 있는 내용을
                        구체적으로 전달해주시면 제안에 응할 가능성이 높아요.
                    </li>
                    <li>• 허위 제안일 경우 서비스 이용이 제한될 수 있어요.</li>
                </ul>
            </div>
            <ButtonBackground>
                <FillButton
                    onClick={() => {
                        if (productId) {
                            suggest()
                        } else {
                            navigate("/suggest/deadline", {
                                state: {
                                    description: suggestDescription,
                                    ...pageData
                                }
                            })
                        }
                    }}
                    className='w-full'
                    variant="Membership"
                    size="B"
                    text='제안하기'
                />
            </ButtonBackground>
        </div>
    );
};

export default SuggestDescription;