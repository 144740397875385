import { useInfiniteQuery, useMutation } from "@tanstack/react-query";
import requestToCastingVote from "./api";
import { offerRequest, offersRequest } from "types/offer";

// 캐스팅 제안
const offer = async (offerRequest: offerRequest) => {
  return await requestToCastingVote
    .post(`offers `, offerRequest)
    .then((res) => res.data);
};

const useOffer = () => {
  return useMutation({
    mutationFn: offer,
  });
};

// 제안 수락
const acceptOffer = async (id: number) => {
  return await requestToCastingVote
    .patch(`offers/${id}/accept`)
    .then((res) => res.data);
};

const useAcceptOffer = () => {
  return useMutation({
    mutationFn: acceptOffer,
  });
};

// 제안 거절
const rejectOffer = async (id: number) => {
  return await requestToCastingVote
    .patch(`offers/${id}/reject`)
    .then((res) => res.data);
};

const useRejectOffer = () => {
  return useMutation({
    mutationFn: rejectOffer,
  });
};

// 받은 제안 목록
const getReceivedOffers = async (offersRequest: offersRequest) => {
  return await requestToCastingVote
    .get(`offers/received`, { params: offersRequest })
    .then((res) => res.data);
};

const useReceivedOffers = (offersRequest: offersRequest) => {
  return useInfiniteQuery({
    queryKey: ["receivedOffers", offersRequest],
    queryFn: ({ pageParam = 0 }) =>
      getReceivedOffers({ ...offersRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 보낸 제안 목록
const getSentOffers = async (offersRequest: offersRequest) => {
  return await requestToCastingVote
    .get(`offers/sent`, { params: offersRequest })
    .then((res) => res.data);
};

const useSentOffers = (offersRequest: offersRequest) => {
  return useInfiniteQuery({
    queryKey: ["sentOffers", offersRequest],
    queryFn: ({ pageParam = 0 }) =>
      getSentOffers({ ...offersRequest, page: pageParam }),
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      return lastPage?.data.last
        ? undefined
        : lastPage.data.pageable.pageNumber + 1;
    },
  });
};

// 제안 읽음 처리
const readOffer = async (id: number) => {
  return await requestToCastingVote
    .patch(`offers/${id}/read`)
    .then((res) => res.data);
};

const useReadOffer = () => {
  return useMutation({
    mutationFn: readOffer,
  });
};

export {
  useOffer,
  useAcceptOffer,
  useRejectOffer,
  useReceivedOffers,
  useSentOffers,
  useReadOffer,
};
