import React, { useState } from 'react';

import ImageModal from 'components/ImageModal';

import { ReactComponent as ArrowLeft } from "assets/arrow_wh_right.svg";

import YouTube from "react-youtube";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";


interface ArtistAttachmentsProps {
    attachments: {
        id: number;
        type: {
            code: string;
            label: string;
        };
        url: string;
        orderNumber: number;
    }[];
    videoLinks: {
        id: number;
        link: string;
        type: {
            code: string;
            label: string;
        };
        orderNumber: number;
    }[];
}

const ArtistAttachments = ({ attachments, videoLinks }: ArtistAttachmentsProps) => {
    const [swiper, setSwiper] = useState<SwiperClass>();
    const [swiperModal, setSwiperModal] = useState<SwiperClass>();
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [imageDetail, setImageDetail] = useState(false);

    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    const opt = {
        height: "203",
        playerVars: {
            autoplay: 0,
        },
    };

    const handlePrev = () => {
        swiperModal?.slidePrev();
    };
    const handleNext = () => {
        swiperModal?.slideNext();
    };

    const filteredAtt = attachments!?.filter(
        (attachment) => attachment.type.code !== "ADDITIONAL_VIDEO"
    ).sort((a, b) => a.orderNumber - b.orderNumber);

    return (
        <div className='flex flex-col gap-4'>
            <div className="relative">
                <div className="absolute right-4 top-4 MCaption14 bg-Gray08/80 z-50 text-Gray00 rounded-full h-7 px-2 py-1">
                    <span>{swiperIndex + 1}</span>
                    <span className="text-Gray05">{" / "}</span>
                    <span className="text-Gray05">{filteredAtt?.length}</span>
                </div>
                <Swiper
                    onActiveIndexChange={(e: SwiperClass) =>
                        setSwiperIndex(e.realIndex)
                    }
                    onSwiper={(e: SwiperClass) => {
                        setSwiper(e);
                    }}
                    spaceBetween={8}
                    slidesPerView={1}
                >
                    {filteredAtt?.map((item, idx) => {
                        return (
                            <SwiperSlide
                                onClick={() => {
                                    setImageDetail(true);
                                    setSwiperIndex(idx);
                                }}
                                key={idx}
                            >
                                <div className="w-full">
                                    <img
                                        src={MEDIA_URL + item.url}
                                        alt="아티스트 상세이미지"
                                        className="w-full aspect-4/5 object-cover"
                                    />
                                </div>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <div className='flex flex-col gap-2'>
                {attachments?.map((item, idx) => {
                    if (item.type.code === "ADDITIONAL_VIDEO") {
                        return (
                            <video
                                key={item.id}
                                controls
                                className="w-full h-[186px]"
                            >
                                <source src={MEDIA_URL + item.url}></source>
                            </video>
                        );
                    }
                })}
                {videoLinks?.map((item, idx) => {
                    let link = "";

                    if (item.link.startsWith("https://www.youtube.com")) {
                        link = item.link.split("watch?v=")[1];
                    } else {
                        link = item.link.split("?")[0].split("/")[3];
                    }
                    return (
                        <YouTube
                            key={item.orderNumber}
                            className={"w-full"}
                            opts={opt}
                            iframeClassName="w-full"
                            videoId={link}
                        />
                    );
                })}
            </div>
            {imageDetail && (
                <ImageModal
                    onClose={() => setImageDetail(false)}
                    className="w-full"
                >
                    <Swiper
                        initialSlide={swiperIndex}
                        onSwiper={(e: SwiperClass) => {
                            setSwiperModal(e);
                        }}
                        slidesPerView={1}
                        spaceBetween={8}
                        className="w-full relative"
                    >
                        {filteredAtt?.map((item, index) => {
                            return (
                                <SwiperSlide
                                    key={index}
                                    onClick={() => {
                                        setImageDetail(false);
                                    }}
                                    className="relative my-auto mx-auto"
                                >
                                    {MEDIA_URL && (
                                        <div className="w-full h-auto">
                                            <img
                                                src={`${MEDIA_URL}${item.url}`}
                                                alt="IMAGES"
                                                className="mx-auto w-full object-contain max-h-[90vh] aspect-4/5"
                                            />
                                        </div>
                                    )}
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <ArrowLeft
                        onClick={() => {
                            handlePrev();
                        }}
                        className="absolute top-2/4 left-5 z-50 rotate-180 cursor-pointer"
                    />
                    <ArrowLeft
                        onClick={() => {
                            handleNext();
                        }}
                        className="absolute top-2/4 right-5 z-50 cursor-pointer"
                    />
                </ImageModal>
            )}
        </div>
    );
};

export default ArtistAttachments;