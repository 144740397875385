import Banner0 from "assets/banner0.png";
import Banner1 from "assets/banner1.png";
import Banner2 from "assets/banner2.png";
import Banner3 from "assets/banner3.png";
import Banner4 from "assets/banner4.png";

export const bannerItems = [
  {
    src: Banner0,
    url: "https://pf.kakao.com/_WlxlAn",
  },
  {
    src: Banner1,
    url: "https://daffy-periwinkle-38f.notion.site/1844e358e81b807a815ef05cd98d0d35",
  },
  {
    src: Banner2,
    url: "https://daffy-periwinkle-38f.notion.site/1844e358e81b80ccbb73fc1368a8675b",
  },
  {
    src: Banner3,
    url: "https://daffy-periwinkle-38f.notion.site/1764e358e81b80b1892dffdfec12f94b",
  },
  {
    src: Banner4,
    url: "https://daffy-periwinkle-38f.notion.site/FAQ-16f4e358e81b8036bb09ff328465d040",
  },
];
