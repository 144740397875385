import { useQuery } from "@tanstack/react-query";

import requestToCastingVote from "./api";

import { shareLinkRequest } from "types/profileShare";

// 공유하기 링크 요청
const getShareLink = async (shareLinkRequest: shareLinkRequest) => {
  return await requestToCastingVote
    .get(`share/request`, { params: shareLinkRequest })
    .then((res) => res.data);
};

const useShareLink = (shareLinkRequest: shareLinkRequest) => {
  return useQuery({
    queryKey: ["shareLink", shareLinkRequest],
    queryFn: () => getShareLink(shareLinkRequest),
    enabled: !!shareLinkRequest.id,
  });
};

// 프로필 공유하기
const getShareProfile = async (value: string) => {
  return await requestToCastingVote
    .get(`share`, { params: { value } })
    .then((res) => res.data);
};

const useShareProfile = (value: string) => {
  return useQuery({
    queryKey: ["shareProfile", value],
    queryFn: () => getShareProfile(value),
  });
};

// 받은 프로필 공유하기
const getShareReceivedProfile = async (value: string) => {
  return await requestToCastingVote
    .get(`share`, { params: { value } })
    .then((res) => res.data);
};

const useShareReceivedProfile = (value: string) => {
  return useQuery({
    queryKey: ["shareReceivedProfile", value],
    queryFn: () => getShareReceivedProfile(value),
  });
};

export { useShareLink, useShareProfile, useShareReceivedProfile };
