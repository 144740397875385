import React, { useState } from "react";
import { ReactComponent as Back } from "assets/icon_back_wh.svg";
import { useNavigate } from "react-router-dom";

import MembershipImage from "assets/img_membership.png";
import Logo from "assets/CastingVote_logo_symbol.png";
import { FillButton } from "components/FillButton";

const Membership = () => {
  const navigate = useNavigate();

  const info = [
    {
      id: 0,
      title: "🎉 캐스팅디렉터 실무진 다수 영입",
      // subTitle: "캐스팅 디렉터, 매니저 등 실무진 영입",
      descrption:
        "지옥에서 온 판사, 베테랑2, 셀러브리티,  사내맞선, 이재, 곧죽습니다, 소년시대, 카지노, 악귀 등 메이저 캐스팅디렉터 다수 영입",
    },
    {
      id: 1,
      title: "☝️ 업계 최고 스탭진",
      // subTitle: "단독 캐스팅 공고 업데이트",
      descrption:
        "연출, 제작, 매니저, 에이전시 등 각 분야 업계 최고 스탭진 영입",
    },
    {
      id: 2,
      title: "📢 독점 캐스팅 오디션 공고 업데이트",
      // subTitle: "스탭 • 공고 프로필 딜리버리",
      descrption:
        "오직 캐스팅보트에서만 확인할 수 있는 미공개 출연, 오디션 공고 매일 업데이트",
    },
    {
      id: 3,
      title: "👊 스탭, 공고 프로필 딜리버리",
      // subTitle: "스탭 • 공고 프로필 딜리버리",
      descrption:
        "더 이상 확인 불가능한 딜리버리 그만! 업계 탑티어 캐스팅디렉터부터 매니지먼트, 제작, 연출진에게 직접 프로필 보내고 피드백까지",
    },
  ];

  return (
    <div className="w-full pb-[103px] min-w-[280px] max-w-[400px] min-h-screen px-5 bg-[#1A1E28] mx-auto">
      <div className="mx-auto px-5 py-2 fixed bg-[#1A1E28] left-2/4 top-0 z-50 -translate-x-2/4 max-w-[400px] w-full BBody20 text-Gray09 ">
        <div className="flex justify-between items-center">
          <Back
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
          <div className="absolute left-2/4 text-Gray00 BBody20 -translate-x-2/4 max-w-[200px] whitespace-nowrap text-ellipsis overflow-hidden">
            멤버십
          </div>
        </div>
      </div>
      <div className="mx-auto w-full text-center pt-[111px] max-w-[207px]">
        <div className="flex items-center gap-1 justify-center">
          <img className="h-7" src={Logo} alt="logo"></img>
          <div className="text-Gray00 BBody20">CV 멤버십</div>
        </div>
        <div className="text-Gray03 mt-2 mb-10 MBody14">
          캐스팅 디렉터부터 단독 오디션까지, 캐스팅보트 한 곳에서!
        </div>
        <img className="w-[186px]" src={MembershipImage} alt="membershipItem" />
      </div>
      <div className="flex flex-col gap-4 mt-[84px]">
        {info.map((item, i) => {
          return (
            <div
              key={item.id}
              className="p-5 text-Gray00 bg-[#0E131E] rounded-[10px]"
            >
              <div className="BBody20 mb-4">{item.title}</div>
              <div className="MCaption14">{item.descrption}</div>
            </div>
          );
        })}
      </div>
      <div className="mt-4 text-Gray04 MCaption12">
        - 프로필 보내기 기능 포인트 별도 구매
      </div>
      <div className="max-w-[400px] bg-[#1A1E28] w-full fixed bottom-0 left-2/4 -translate-x-2/4 pb-8 px-5">
        <FillButton
          variant="Membership"
          size={"B"}
          onClick={() => {
            navigate("/membership/subscribe", { replace: true });
          }}
          text="멤버십 시작하기"
        ></FillButton>
      </div>
    </div>
  );
};

export default Membership;
