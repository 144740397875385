import React, { useEffect, useState } from "react";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useLocation, useNavigate, useNavigationType, useParams } from "react-router-dom";
import { ReactComponent as SelectCastingCall } from "assets/selectCastingCall.svg";
import BottomButtonPopup from "components/BottomButtonPopup";
import { Toast } from "hooks/useToast";
import BottomPopup from "components/BottomPopup";
import Button from "components/Button";
import requestToCastingVote from "utils/requestToCastingVote";
import ArtistListCard from "components/ArtistListCard";
import { splitBirth } from "utils/onBirth";
import ButtonBackground from "components/ButtonBackground";
import { useInView } from "react-intersection-observer";
import { useScroll } from "hooks/useScroll";
import useLocalStorage from "use-local-storage";
import { useCastingsByCastingCall, useChangeCastingStatus, useSelectCastingProfiles } from "apis/castingStaff";
import { useReceivedProfiles } from "apis/deliveryProfile";

const CastingProcess = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();
  const isRef = location.hash;

  const { id } = useParams();
  const { state } = useLocation();

  const [tabInfo, setTabInfo] = useState({ id: 0, role: "APPLIED" });


  const scrollKey = `scrollIndex${location.pathname}`;
  const [scrollY, setScrollY] = useLocalStorage(scrollKey, 0);

  const [selectedList, setSelectedList] = useState<number[]>([]);
  const [selectMode, setSelectMode] = useState(false);
  const [selectType, setSelectType] = useState(false);
  const [selectedType, setSelectedType] = useState("APPLIED");
  const [pageData, setPageData] = useState<{
    castingCallId: null | number;
    status: string;
    page: number;
    size: number;
  }>({
    castingCallId: Number(id),
    status: tabInfo.role,
    page: 0,
    size: 20,
  });

  const { y } = useScroll();

  useEffect(() => {
    setScrollY(y);
  }, [y]);

  useEffect(() => {
    if (scrollY !== 0) window.scrollTo(0, scrollY);
  }, []);

  const {
    data,
    fetchNextPage: listNext,
    hasNextPage: listHas,
    refetch,
  } = useCastingsByCastingCall({
    id: pageData.castingCallId,
    status: pageData.status,
  });
  const { ref: listRef, inView: listInView } = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (listInView && listHas) {
      listNext();
    }
  }, [listInView]);

  const {
    data: received,
    fetchNextPage,
    hasNextPage,
    refetch: receivedRefetch,
  } = useReceivedProfiles({ castingCallId: Number(id) });

  const { ref, inView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  const mutate = useChangeCastingStatus();

  const castingStatus = state?.status;

  const { mutate: APPLIED_PROFILE } = useSelectCastingProfiles();

  const tab = [
    { id: 0, title: "지원", role: "APPLIED" },
    { id: 1, title: "후보", role: "SHORTLIST_SELECTED" },
    { id: 2, title: "리스트업", role: "FINALIST_SELECTED" },
    { id: 3, title: "캐스팅 확정", role: "PASSED" },
  ];

  const type = [
    { id: 0, title: "지원 프로필", role: "APPLIED" },
    { id: 1, title: "후보 추가", role: "SHORTLIST_SELECTED" },
    { id: 2, title: "리스트업", role: "FINALIST_SELECTED" },
    { id: 3, title: "캐스팅 확정", role: "PASSED" },
  ];

  const handleDownload = async () => {
    try {
      const response = await requestToCastingVote.get(
        `/castingcalls/${state?.castingCallId}/castings/excel?status=${selectedType}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "test.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("엑셀 파일 다운로드 중 오류가 발생했습니다.", error);
    }
  };

  const handleShortlist = () => {
    if (tabInfo.role === "APPLIED") {
      const data = {
        castingCallId: Number(id),
        deliveryProfileIds: selectedList,
      };
      APPLIED_PROFILE(data, {
        onSuccess: (res) => {
          Toast.success("프로필을 이동했어요.");
          setSelectMode(false);
          setSelectType(false);
          setSelectedType(tabInfo.role);
          setSelectedList([]);
          receivedRefetch();
        },
      });
    } else {
      const data = {
        castingCallId: state?.castingCallId,
        status: selectedType,
        castingIds: selectedList,
      };
      mutate.mutate(data, {
        onSuccess: () => {
          setSelectMode(false);
          setSelectType(false);
          setSelectedType(tabInfo.role);
          setSelectedList([]);
          refetch();
          if (selectedType === "PASSED") {
            // navigate(-1);
            Toast.success("캐스팅 확정되었어요.");
          } else {
            Toast.success("프로필을 이동했어요.");
          }
        },
        onError: () => {
          setSelectType(false);
        },
      });
    }
  };
  return (
    <>
      <HeaderWithBackButton title={state.title} />
      <div className="pb-24">
        {!selectMode && (
          <div className="flex items-center mt-4">
            {tab.map((item) => {
              return (
                <div
                  className={`flex-1 text-center cursor-pointer pb-4 BBody14 ${tabInfo.id === item.id
                    ? "border-b-2 border-[#171717] text-Gray09"
                    : "border-b border-Gray03 text-Gray05"
                    }`}
                  onClick={() => {
                    setTabInfo({
                      ...tabInfo,
                      id: item.id,
                      role: item.role,
                    });
                    setSelectedType(item.role);
                    setPageData({
                      ...pageData,
                      status: item.role,
                    });
                  }}
                  key={item.id}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
        )}
        <div>
          {tabInfo.role === "APPLIED" ? (
            <div className="px-5">
              <div className="py-4 w-full text-right">
                {received?.pages[0].data.content.length !== 0 &&
                  castingStatus !== "CLOSED" && (
                    <div
                      onClick={() => {
                        setSelectMode((selectMode) => !selectMode);
                        if (selectMode) {
                          setSelectedList([]);
                        }
                      }}
                      className="cursor-pointer text-right inline-block text-Blue04 BBody14"
                    >
                      {selectMode ? "취소" : "캐스팅단계이동"}
                    </div>
                  )}
              </div>
              {received?.pages[0].data.content.length === 0 ? (
                <div className="mt-36 items-center text-center">
                  <div className="text-Gray05 RBody14">프로필이 없어요.</div>
                </div>
              ) : (
                <div className="grid grid-cols-3 gap-y-4 gap-x-2">
                  {received?.pages.map((item: any) => {
                    return item.data.content.map((receivedItem: any) => {
                      return (
                        <ArtistListCard
                          onClick={() => {
                            if (selectMode) {
                              if (
                                selectedList.includes(
                                  receivedItem.deliveryProfileId
                                )
                              ) {
                                setSelectedList(
                                  selectedList.filter(
                                    (id) =>
                                      id !== receivedItem.deliveryProfileId
                                  )
                                );
                              } else {
                                setSelectedList([
                                  ...selectedList,
                                  receivedItem.deliveryProfileId,
                                ]);
                              }
                            } else {
                              navigate(
                                `/staff/received/${receivedItem.deliveryProfileId
                                }${isRef === "#ref" ? "#ref" : ""}`
                              );
                            }
                          }}
                          selected={selectedList.includes(
                            receivedItem.deliveryProfileId
                          )}
                          isPremium={
                            receivedItem?.type?.code === "PREMIUM_DELIVERY"
                          }
                          selectMode={selectMode}
                          subTitle={`${splitBirth(receivedItem.birthDate)}`}
                          key={receivedItem?.deliveryProfileId}
                          src={receivedItem.thumbnailUrl}
                          title={receivedItem.profileName}
                          isOffered={receivedItem.isOffered}
                        />
                      );
                    });
                  })}
                </div>
              )}
              {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
            </div>
          ) : (
            <div className="px-5">
              <div className="py-4">
                <div className="flex items-center justify-between">
                  {data?.pages[0].data.content.length !== 0 && (
                    <div
                      onClick={() => {
                        handleDownload();
                      }}
                      className="text-Blue04 cursor-pointer BBody14"
                    >
                      엑셀 다운로드
                    </div>
                  )}
                  {data?.pages[0].data.content.length !== 0 &&
                    castingStatus !== "CLOSED" &&
                    tabInfo.role !== "PASSED" && (
                      <div
                        onClick={() => {
                          setSelectMode((selectMode) => !selectMode);
                          if (selectMode) {
                            setSelectedList([]);
                          }
                        }}
                        className="cursor-pointer text-right text-Blue04 BBody14"
                      >
                        {selectMode ? "취소" : "캐스팅단계이동"}
                      </div>
                    )}
                </div>
              </div>
              {data?.pages[0].data.content.length === 0 ? (
                <div className="mt-36 items-center text-center">
                  <div className="text-Gray05 RBody14">프로필이 없어요.</div>
                </div>
              ) : (
                <div className="grid grid-cols-3 gap-y-4 gap-x-2">
                  {data?.pages.map((item) => {
                    return item.data.content.map((listItem: any) => {
                      return (
                        <ArtistListCard
                          key={listItem?.castingId}
                          onClick={() => {
                            if (selectMode) {
                              if (selectedList.includes(listItem.castingId)) {
                                setSelectedList(
                                  selectedList.filter(
                                    (id) => id !== listItem.castingId
                                  )
                                );
                              } else {
                                setSelectedList([
                                  ...selectedList,
                                  listItem.castingId,
                                ]);
                              }
                            } else {
                              navigate(
                                `/staff/feedback/${listItem.profileId}${isRef === "#ref" ? "#ref" : ""
                                }`,
                                {
                                  state: {
                                    castingCallId: state.castingCallId,
                                    castingId: listItem.castingId,
                                  },
                                }
                              );
                            }
                          }}
                          isPremium={
                            listItem?.type?.code === "PREMIUM_DELIVERY"
                          }
                          isOffered={listItem.isOffered}
                          selected={selectedList.includes(listItem.castingId)}
                          subTitle={`${splitBirth(listItem.birthDate)}`}
                          selectMode={selectMode}
                          src={listItem?.thumbnailUrl}
                          title={listItem.profileName}
                        />
                      );
                    });
                  })}
                </div>
              )}
              <div ref={listRef} />
            </div>
          )}
        </div>
        {selectType && (
          <BottomPopup
            onClose={() => {
              setSelectedType(tabInfo.role);
              setSelectType(false);
            }}
          >
            <div className="BBody20 text-Gray09">캐스팅 단계 선택</div>
            <div className="flex flex-col gap-2 mt-5 overflow-y-auto">
              {type
                .filter((typeItem) => {
                  if (tabInfo.role === "APPLIED") {
                    return typeItem.id === 1;
                  } else if (tabInfo.role === "SHORTLIST_SELECTED") {
                    return typeItem.id > 1;
                  } else if (tabInfo.role === "FINALIST_SELECTED") {
                    return typeItem.id > 2;
                  }
                })
                .map((typeItem) => (
                  <div
                    onClick={() => {
                      setSelectedType(typeItem.role);
                    }}
                    key={typeItem.id}
                    className={`px-4 py-3 cursor-pointer box-border MBody16 rounded-[10px] ${selectedType === typeItem.role
                      ? "bg-Blue01 border border-Blue04"
                      : "bg-Gray01"
                      }`}
                  >
                    {typeItem.title}
                  </div>
                ))}
            </div>
            <ButtonBackground>
              <Button
                onClick={() => {
                  setSelectType(false);
                  setSelectedType(tabInfo.role);
                }}
                text={"닫기"}
                className="w-full text-Gray05 flex-1 bg-Gray03 "
              />
              <Button
                onClick={() => {
                  handleShortlist();
                }}
                text={"이동하기"}
                className="w-full bg-Blue04 flex-1 text-Gray00 MBody16"
              />
            </ButtonBackground>
          </BottomPopup>
        )}
        {selectMode && (
          <BottomButtonPopup
            onClose={() => {
              setSelectMode(false);
            }}
          >
            <div
              onClick={() => {
                if (selectedList.length !== 0) {
                  setSelectType(true);
                } else {
                  Toast.error("프로필을 선택해주세요");
                }
              }}
              className="flex flex-col pt-2 justify-center items-center MBody14 cursor-pointer text-Gray00"
            >
              <SelectCastingCall />

              <div>캐스팅 단계 이동</div>
            </div>
          </BottomButtonPopup>
        )}
      </div>
    </>
  );
};

export default CastingProcess;
