import { useState } from "react";

import Button from "components/Button";
import { useNavigate, useParams } from "react-router-dom";
import BottomPopup from "components/BottomPopup";
import { Toast } from "hooks/useToast";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import ButtonBackground from "components/ButtonBackground";
import ScrollToTop from "components/ScrollToTop";
import { MY_ROLE } from "utils/onRoleStatus";
import { Profile } from "pages/StaffDetail";

import ManagerSendArtistProfile from "./ManagerSendArtistProfile";
import { usePointCheck, usePointCheckByRole } from "apis/point";
import { useDelveryProfileToCasting } from "apis/deliveryProfile";
import { useCastingcall } from "apis/castingcall";
import useLoginInfo from "stores/loginInfo";

const CastingDetail = () => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

  const role = MY_ROLE();

  const navigate = useNavigate();
  const [artistSelectModal, setArtistSelectModal] = useState(false);
  const [sendProfile, setSendProfile] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);
  const { loginInfo } = useLoginInfo();

  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | null;
    profileIds: null | number[];
    castingCallId: number | null;
    type: "CASTING";
  }>({
    staffId: null,
    profileIds: null,
    castingCallId: null,
    type: "CASTING",
  });

  const { itemId } = useParams();

  const { data, isLoading, refetch } = useCastingcall(Number(itemId));
  const CastingDetail = data?.data;

  const { data: rolePointData } = usePointCheckByRole({
    role: CastingDetail?.staffRole?.code!,
    type: sendProfileData.type
  });

  const rolePoint = rolePointData?.data;

  const deadline =
    CastingDetail?.deadlineDate &&
    `${CastingDetail?.deadlineDate?.split("-")[1]}월 ${CastingDetail?.deadlineDate?.split("-")[2]
    }일`;

  const castingInfo = [
    {
      id: 0,
      Title: "작품제목",
      Desc: CastingDetail?.productionTitle,
    },
    {
      id: 1,
      Title: "분야",
      Desc: CastingDetail?.productionFormat?.label,
    },
    {
      id: 2,
      Title: "제작사",
      Desc: CastingDetail?.companyName,
    },
    {
      id: 3,
      Title: "감독",
      Desc: CastingDetail?.directorName,
    },
    {
      id: 4,
      Title: "배역",
      Desc: CastingDetail?.shootingDetails.role.label,
    },
    {
      id: 5,
      Title: "성별",
      Desc: CastingDetail?.shootingDetails.gender.label,
    },
    {
      id: 6,
      Title: "나이",
      Desc: `${CastingDetail?.shootingDetails.minAge}세 ~ ${CastingDetail?.shootingDetails.maxAge}세`,
    },
    {
      id: 7,
      Title: "출연료",
      Desc: CastingDetail?.shootingDetails.fee,
    },
    {
      id: 8,
      Title: "마감일자",
      Desc: deadline,
    },
    {
      id: 9,
      Title: "캐스팅",
      Desc: CastingDetail?.staffName,
    },
  ];

  const castingDesc = [
    {
      id: 0,
      title: "상세내용",
      desc: CastingDetail?.description,
    },
  ];

  const mutate = useDelveryProfileToCasting();

  const usePoint = selectedProfiles.length * (rolePoint?.point || 0);

  const {
    isError,
  } = usePointCheck(usePoint);

  const canSendProfile = role === "ROLE_ARTIST" || (role === "ROLE_MANAGER" && CastingDetail?.staffId !== loginInfo?.memberId);

  const handleSendProfileClick = () => {
    if (role === "ROLE_ARTIST" && loginInfo.profileId === null) {
      navigate("/artist/my");
    } else if (role === "ROLE_MANAGER") {
      handleShowProfileModal();
    } else {
      setSendProfile(true);
      setSendProfileData({
        ...sendProfileData,
        staffId: CastingDetail?.staffId,
        castingCallId: CastingDetail?.castingCallId,
        profileIds: [loginInfo.profileId!]
      })
    }
  };

  const profileSend = () => {
    mutate.mutate(sendProfileData, {
      onSuccess: () => {
        Toast.success("프로필을 보냈어요.");
        setSendProfileData({
          ...sendProfileData,
          staffId: null,
          profileIds: null,
          castingCallId: null,
          type: "CASTING",
        })
        setSelectedProfiles([])
      },
      onSettled: () => {
        setSendProfile(false);
      },
    }
    );
  };

  const handleShowProfileModal = (
  ) => {
    setArtistSelectModal(true);
    setSendProfileData({
      ...sendProfileData
    });
  };

  if (isLoading) return null;

  return (
    <>
      {artistSelectModal && (
        <ManagerSendArtistProfile
          buttonText={isError ? "포인트가 부족해요" : `${usePoint}포인트로 프로필 보내기`}
          title="어떤 프로필을 보낼까요?"
          disabled={isError}
          onClick={() => {
            setSendProfileData({
              ...sendProfileData,
              staffId: CastingDetail?.staffId,
              castingCallId: CastingDetail?.castingCallId,
              profileIds: selectedProfiles.map((item) => item.profileId)
            })
            setSendProfile(true);
          }}
          setIsActive={setArtistSelectModal}
          setSelectedProfiles={setSelectedProfiles}
          isActive={artistSelectModal}
          selectedProfiles={selectedProfiles}
        />
      )}
      <HeaderWithBackButton title={""} />
      <div className="pb-24 p-5">
        <ScrollToTop />
        {sendProfile && (
          <BottomPopup onClose={() => setSendProfile(false)}>
            <div className="flex flex-col gap-4">
              <div className="BBody20">
                👀 프로필 보내기 전에
                <br />
                아래 사항을 꼭 확인해주세요
              </div>
              <div className="w-full p-4 rounded-[10px] bg-Gray01 text-Gray05">
                <ol className="list-disc list-inside MBody16">
                  <li>
                    스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지
                    않으니 허위 공고 또는 제안을 조심하세요.
                  </li>
                  <li>
                    보내는 프로필에 영상을 첨부하면 캐스팅 확률이 높아져요!
                  </li>
                  <li>프로필 받은 스탭은 내 연락처를 열람할 수 있어요.</li>
                  <li>
                    보낸 프로필로 사용한 포인트는 30일 이후에도 피드백이 없을
                    경우 최대 7일 이내 자동으로 환불돼요.
                  </li>
                </ol>
              </div>
              <ButtonBackground>
                <Button
                  onClick={() => {
                    profileSend();
                    role === "ROLE_MANAGER" && setArtistSelectModal(false);
                  }}
                  className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
                  text={role === "ROLE_MANAGER" ? "확인했어요" : `${rolePoint?.point}포인트로 프로필 보내기`}
                ></Button>
              </ButtonBackground>
            </div>
          </BottomPopup>
        )}
        <div>
          <div className="BBody20">{CastingDetail?.title}</div>
          {CastingDetail?.attachments.map((item: any) => {
            if (item.type.code === "THUMBNAIL") {
              return (
                <div key={item.id} className="mt-4">
                  {MEDIA_URL && (
                    <img
                      src={`${MEDIA_URL}${item.url}`}
                      className="w-full h-[360px] object-cover rounded-[10px]"
                      alt="thumbnail"
                    />
                  )}
                </div>
              );
            }

            return null;
          })}
          <div className="flex mt-4 flex-col mb-10 items-start gap-4">
            <div className="w-full">
              <div>
                <div className="bg-[#F9FAFA] w-full rounded-[10px] p-4 flex flex-col gap-2">
                  {castingInfo.map((item) => {
                    if (item.Desc) {
                      return (
                        <div key={item.id}>
                          <div className="flex gap-[39px] items-start BBody14">
                            <div className="flex items-center w-[75px] text-Gray05">
                              {item.Title}
                            </div>
                            <div className="text-Gray09 flex-1">
                              {item.Desc}
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-10 w-full">
            {castingDesc.map((item) => {
              if (item.desc) {
                return (
                  <div key={item.id}>
                    <div className="BBody18">{item.title}</div>
                    <div className="RBody16 mt-4 break-words whitespace-pre-wrap">
                      {item.desc}
                    </div>
                  </div>
                );
              }

              return null;
            })}
            {CastingDetail?.attachments.some(
              (attachment: any) => attachment.type.code === "FILE"
            ) && (
                <div>
                  <div className="BBody18">첨부파일</div>
                  <div className="RBody16 mt-4">
                    {CastingDetail?.attachments.map((attItem: any) => {
                      if (attItem.type.code === "FILE") {
                        return (
                          <div
                            className="cursor-pointer inline-block"
                            onClick={() => {
                              window.open(
                                `${MEDIA_URL && MEDIA_URL}${attItem?.url}`,
                                "_blank",
                                "noopener, noreferrer"
                              );
                            }}
                            key={attItem.id}
                          >
                            {attItem?.originalFilename}
                          </div>
                        );
                      }

                      return null;
                    })}
                  </div>
                </div>
              )}
          </div>
        </div>
        {/* MANAGER, CASTING_DIRECTOR, DIRECTOR, PRODUCER */}
        {canSendProfile && (
          <ButtonBackground>
            <Button
              onClick={handleSendProfileClick}
              className="bg-Blue04 w-full py-3 MBody16 text-white border-none"
              text={"프로필 보내기"}
            />
          </ButtonBackground>
        )}

      </div>
    </>
  );
};

export default CastingDetail;
