import React, { useEffect, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import Thumbnail from "assets/thumbnail.png";

import { useMyProductions } from 'apis/my';
import ButtonBackground from 'components/ButtonBackground';
import { LineButton } from 'components/LineButton';
import { useLocation, useNavigate } from 'react-router';
import HeaderWithBackButton from 'components/HeaderWithBackButton';

const StaffMyProducts = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;

    const artistData = location?.state;

    const { ref, inView } = useInView({
        threshold: 0.5,
    });
    const {
        data,
        fetchNextPage,
        hasNextPage,
    } = useMyProductions({ status: "ACTIVE", isActiveProfile: true });

    useEffect(() => {
        if (inView && hasNextPage) {
            fetchNextPage();
        }
    }, [inView]);

    const products = useMemo(
        () =>
            data ? data?.pages?.flatMap((staff) => staff.data.content) : [],
        [data]
    );
    return (
        <div className='pb-24'>
            <HeaderWithBackButton title={''} />
            <div className='BBody20 text-Gray09 px-5 py-1'>
                제안할 작품을 선택해주세요
            </div>
            {products.length === 0 ? (
                <div className="mt-36 items-center text-center">
                    <div className="text-Gray05 RBody14">제안할 작품이 없습니다.</div>
                </div>
            ) : (
                <div>
                    {products?.map((item) => {
                        return (
                            <div onClick={() => {
                                navigate("/suggest/castingcall", {
                                    state: { productId: item.productionId, ...artistData }
                                })
                            }} key={item.productionId}
                                className='p-5 cursor-pointer flex items-start gap-4 border-b border-Gray02'>
                                <img className='w-12 h-12 rounded object-cover' src={item.thumbnailUrl ? MEDIA_URL + item.thumbnailUrl : Thumbnail} alt="작품 썸네일" />
                                <div>
                                    <div className='MCatpion12 text-Gray05'>{item.format.label}</div>
                                    <div className='MBody18 text-Gray09'>{item.title}</div>
                                </div>
                            </div>
                        )
                    })}
                    {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
                </div>
            )
            }
            <ButtonBackground>
                <LineButton
                    onClick={() => {
                        navigate("/suggest/description", {
                            state: { productId: null, ...artistData },
                        });
                    }}
                    className='w-full'
                    variant="normal"
                    size="L"
                    text='작품 없이 제안하기'
                />
            </ButtonBackground>
        </div >
    );
};

export default StaffMyProducts;