import React from "react";
import Logo from "../assets/CastingVote_logo_white.png";
import { ReactComponent as Instagram } from "../assets/icon_insta_footer.svg";
import { ReactComponent as Facebook } from "../assets/icon_facebook_footer.svg";
import { ReactComponent as Tiktok } from "../assets/icon_tiktok_footer.svg";
import { ReactComponent as Youtube } from "../assets/icon_youtube_footer.svg";
import { ReactComponent as Blog } from "../assets/icon_blog_footer.svg";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const Sns = [
    {
      title: "youtube",
      src: Youtube,
      link: "https://www.youtube.com/@Castingvote_%EC%BA%90%EC%8A%A4%ED%8C%85%EB%B3%B4%ED%8A%B8",
    },
    {
      title: "tiktok",
      src: Tiktok,
      link: "https://www.tiktok.com/@castingvote.official",
    },
    {
      title: "insta",
      src: Instagram,
      link: "https://www.instagram.com/castingvote.im",
    },
    {
      title: "meta",
      src: Facebook,
      link: "https://www.facebook.com/profile.php?id=61561061871831",
    },
    {
      title: "blog",
      src: Blog,
      link: "https://blog.naver.com/castingvote_official",
    },
  ];


  const comInfo = [
    {
      id: 1,
      title: "공지사항",
      url: "https://daffy-periwinkle-38f.notion.site/16f4e358e81b80d79a7ccf980d0ad2d0?pvs=4",
    },
    { id: 2, title: "개인정보처리방침", url: "/private" },
    { id: 3, title: "이용약관", url: "/terms" },
  ]

  if (location.pathname !== "/") return null;

  return (
    <div className="w-full mx-auto pt-5 pb-[70px] bg-Gray08 px-5">
      <div className="flex justify-between mb-5 items-center">
        <img className="h-6" src={Logo} alt="로고" />
        <div className="flex justify-between gap-4">
          {Sns.map((item, i) => {
            return (
              <item.src
                key={i}
                onClick={() => {
                  window.open(`${item.link}`, "_blank", "noopener, noreferrer");
                }}
                className="w-5 h-5 cursor-pointer"
              />
            );
          })}
        </div>
      </div>
      <div className="text-white flex flex-col RCaption10 gap-1">
        <div className="flex gap-2">
          <div className="BCaption10">대표</div>
          <div>유성봉</div>
        </div>
        <div className="flex gap-2">
          <div className="BCaption10">사업자등록번호</div>
          <div>772-87-03229</div>
        </div>
        <div className="flex gap-2">
          <div className="BCaption10">통신판매업 신고번호</div>
          <div>2024-서울강남-02551호</div>
        </div>
        <div className="flex gap-2">
          <div className="BCaption10">주소</div>
          <div>서울 강남구 영동대로 701 W타워 8층 (청담동)</div>
        </div>
        <div className="flex gap-x-2 flex-wrap">
          <div className="flex gap-2">
            <div className="BCaption10">고객센터</div>
            <div>02-512-2468</div>
          </div>
          <div className="flex gap-2">
            <div className="BCaption10">개인정보정책 및 담당</div>
            <div>유성봉</div>
          </div>
        </div>
      </div>
      <div className="flex gap-4 text-white BCaption10 mt-4">
        {comInfo.map((item) => {
          return (
            <div
              key={item.id}
              onClick={() => {
                if (item.id === 1) {
                  window.open(`${item.url}`, "_blank", "noopener, noreferrer");
                } else {
                  navigate(`${item.url}`);
                }
              }}
              className="cursor-pointer"
            >
              {item.title}
            </div>
          );
        })}
        <div className="cursor-pointer" onClick={() => {
          window.location.href = `mailto:marketing@castingvote.im`
        }}>광고문의</div>
      </div>
      <hr className="text-white opacity-10 my-2" />
      <div className="text-white RCaption10 opacity-30">
        Copyright ⓒ CASTINGVOTE ALL RIGHTS RESERVED.
      </div>
    </div>
  );
};

export default Footer;
