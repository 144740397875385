import React, { useMemo, useState } from "react";

import MainWrap from "components/MainWrap";
import ArtistCard from "components/ArtistCard";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css/effect-fade";

import { Autoplay, EffectFade } from "swiper/modules";
import CastingCard from "components/CastingCard";
import Header from "components/Header";
import { splitBirth } from "utils/onBirth";
import { useBanner, useMainCasting, useMainCastingNew, useMainCastingPick } from "apis/main";
import { bannerItems } from "./Banner/BannerItems";

const Main = () => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [swiperIndex, setSwiperIndex] = useState(0);

  const { data: banner } = useBanner("TOP");
  const { data: castingData } = useMainCasting({ page: 0, size: 20 })
  const { data: castingPickData } = useMainCastingPick({ page: 0, size: 20 })
  const { data: newCastingData } = useMainCastingNew({ page: 0, size: 20 })

  const castings = useMemo(
    () => (castingData ? castingData?.pages?.flatMap((casting) => casting.data.content) : []),
    [castingData]
  );
  const castingPicks = useMemo(
    () => (castingPickData ? castingPickData?.pages?.flatMap((castingPick) => castingPick.data.content) : []),
    [castingPickData]
  );
  const newCastings = useMemo(
    () => (newCastingData ? newCastingData?.pages?.flatMap((newCasting) => newCasting.data.content) : []),
    [newCastingData]
  );

  return (
    <>
      <div className="rolling-container MCaption12">
        <div className="rolling-text">
          {banner?.data.data?.map((item: any) => {
            return (
              <div
                key={item.bannerId}
                dangerouslySetInnerHTML={{ __html: item.content }}
              />
            );
          })}
        </div>
      </div>
      <Header />
      <div className="pb-10">
        <div className="relative">
          <div onClick={() => {
            navigate("/banner")
          }} className="absolute right-4 cursor-pointer text-Gray00 bottom-4 MCaption14 bg-Gray08/80 z-50 rounded-full h-7 px-2 py-1">
            <span>{swiperIndex + 1}</span>
            <span className="text-Gray05">{" / "}</span>
            <span className="text-Gray05">{bannerItems?.length} +</span>
          </div>
          <Swiper
            onActiveIndexChange={(e: SwiperClass) =>
              setSwiperIndex(e.realIndex)
            }
            onSwiper={(e: SwiperClass) => {
              setSwiper(e);
            }}
            effect="fade"
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade]}
            loop={true}
            spaceBetween={8}
            slidesPerView={1}
          >
            {bannerItems.map((item) => {
              return (
                <SwiperSlide
                  key={item.url}
                  className="w-fit">
                  <img
                    onClick={() => {
                      window.open(
                        item.url,
                        "noopener,noreferrer"
                      );
                    }}
                    className="cursor-pointer"
                    src={item.src}
                    alt=""
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <MainWrap
          className="relative"
          onClick={() => {
            navigate("/casting");
          }}
          more={true}
          title="진행중인 캐스팅"
        >
          <Swiper
            slidesPerView={
              castings?.length! < 4 ? 2 : "auto"
            }
            spaceBetween={16}
          >
            {castings.map((item, i) => {
              if (item.productionThumbnailUrl !== null)
                return (
                  <SwiperSlide
                    className={
                      castings.length! < 2
                        ? "w-full"
                        : "!w-fit"
                    }
                    key={item.castingCallId}
                  >
                    <div className="w-full">
                      <CastingCard
                        onClick={() => {
                          navigate(`/casting/${item.castingCallId}`);
                        }}
                        title={item?.title}
                        src={item?.productionThumbnailUrl}
                        type={item?.productionFormat?.label}
                      />
                    </div>
                  </SwiperSlide>
                );
            })}
          </Swiper>
        </MainWrap>
        <MainWrap
          className="relative"
          more
          onClick={() => {
            navigate("/artist/pick");
          }}
          title="캐스팅보트 P!ck"
        >
          <Swiper
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay]}
            slidesPerView={castingPicks.length! < 4 ? 2 : "auto"}
            spaceBetween={16}
          >
            {castingPicks.map((item: any, i: number) => {
              return (
                <SwiperSlide
                  className={
                    castingPicks.length! < 2 ? "w-full" : "!w-fit"
                  }
                  key={i}
                >
                  <div className="w-full">
                    <ArtistCard
                      isMembership={true}
                      onClick={() => {
                        navigate(`/artist/${item.profileId}`);
                      }}
                      src={item.thumbnailUrl}
                      title={item.profileName}
                      subTitle={`${splitBirth(item?.birthDate)}`}
                      description={
                        item.productionTitle
                          ? item.productionTitle
                          : "미공개작품"
                      }
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </MainWrap>
        <MainWrap
          className="relative"
          title="새로 올라온 프로필"
          onClick={() => {
            navigate("/artist");
          }}
          more
        >
          <Swiper
            // autoplay={{ delay: 2000, disableOnInteraction: false }}
            // modules={[Autoplay]}
            slidesPerView={newCastings.length! < 4 ? 2 : "auto"}
            spaceBetween={16}
          >
            {newCastings.map((item, i) => {
              const date = item.birthDate?.split("-")[0];
              return (
                <SwiperSlide
                  className={
                    newCastings.length! < 2 ? "w-full" : "!w-fit"
                  }
                  key={item.profileId}
                >
                  <div className="w-full">
                    <ArtistCard
                      isMembership={item.isMembership}
                      src={item.thumbnailUrl}
                      onClick={() => {
                        navigate(`/artist/${item.profileId}`);
                      }}
                      title={item.profileName}
                      subTitle={`${date}`}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </MainWrap>
        {/* <MainWrap more={false} title="캐스팅보트 핫클립">
        <YouTube
          className={"w-full"}
          opts={opt}
          iframeClassName="youtubeRounded"
          videoId="QLXEu3SVbh8"
        />
      </MainWrap> */}
      </div >
    </>
  );
};

export default Main;
