import React, { useState } from "react";
import Button from "components/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BottomPopup from "components/BottomPopup";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import Textarea from "components/Textarea";
import { Toast } from "hooks/useToast";
import ScrollToTop from "components/ScrollToTop";
import ButtonBackground from "components/ButtonBackground";
import { HAS_PROFILE, MY_ROLE } from "utils/onRoleStatus";
import { FillButton } from "components/FillButton";
import StaffDescCard from "./StaffDetail/_components/StaffDescCard";
import StaffArtistsProfiles from "./StaffDetail/_components/StaffArtistsProfiles";
import StaffProductions from "./StaffDetail/_components/StaffProductions";
import ManagerSendArtistProfile from "./ManagerSendArtistProfile";
import { useStaffProfile } from "apis/profile";
import { usePointCheck, usePointCheckByRole } from "apis/point";
import { useDeliveryProfileToStaff } from "apis/deliveryProfile";
import useLoginInfo from "stores/loginInfo";

export interface Profile {
  birthDate?: string;
  profileId: number;
  profileName: string;
  thumbnailUrl: string;
}

const StaffDetail = () => {
  const { loginInfo } = useLoginInfo();

  const myRole = MY_ROLE();
  const hasProfile = HAS_PROFILE();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { staffId } = useParams();

  const [artistSelectModal, setArtistSelectModal] = useState(false);
  const [sendProfile, setSendProfile] = useState(false);
  const [selectedProfiles, setSelectedProfiles] = useState<Profile[]>([]);

  const [sendProfileData, setSendProfileData] = useState<{
    staffId: number | null;
    profileIds: number[] | null;
    description?: string;
    type: string;
  }>({
    staffId: null,
    profileIds: null,
    description: "",
    type: "",
  });

  const { data, isLoading } = useStaffProfile(Number(staffId));

  const StaffData = data?.data;

  const mutate = useDeliveryProfileToStaff();

  const { data: rolePointData } = usePointCheckByRole({
    role: StaffData?.role.code!,
    type: sendProfileData.type!
  });

  const rolePoint = rolePointData?.data;

  const usePoint = selectedProfiles.length * (rolePoint?.point || 0);

  const {
    data: remainPoint,
    isSuccess,
    isError,
  } = usePointCheck(usePoint);

  const sendProfileToStaff = () => {
    mutate.mutate(sendProfileData,
      {
        onSuccess: () => {
          Toast.success(`${StaffData?.profileName}님께 프로필을 보냈어요.`);
          setSendProfileData({ ...sendProfileData, description: "" })
        },
        onSettled: () => {
          setSendProfile(false);
        },
      }
    );
  };

  const isPending = StaffData?.status?.code === "PENDING";

  const staffRole = StaffData?.role?.code;

  const point = rolePoint?.point.toLocaleString()

  if (isLoading) return null;

  return (
    <>
      {artistSelectModal && (
        <ManagerSendArtistProfile
          buttonText={isError ? "포인트가 부족해요" : `${usePoint}포인트로 프로필 보내기`}
          title={"어떤 프로필을 보낼까요?"}
          disabled={isError}
          onClick={() => {
            setSendProfile(true);
            setSendProfileData({
              ...sendProfileData,
              profileIds: selectedProfiles.map((item) => item.profileId)
            })
          }}
          setIsActive={setArtistSelectModal}
          setSelectedProfiles={setSelectedProfiles}
          isActive={artistSelectModal}
          selectedProfiles={selectedProfiles}
        />
      )}
      <HeaderWithBackButton title={""}>
        <div className="flex gap-4">
          {/* <Bookmark className="w-6 h-6" /> */}
        </div>
      </HeaderWithBackButton>
      <div className="pt-5 px-5 pb-24 flex flex-col">
        <ScrollToTop />

        <StaffDescCard
          staffName={StaffData?.profileName!}
          imageUrl={StaffData?.thumbnailUrl!}
          staffRole={StaffData?.role.label!}
          staffAgency={StaffData?.agency!}
          filmography={StaffData?.filmographies!}
          filmographySummary={StaffData?.filmographySummary!}
        />

        {sendProfile && sendProfileData.type !== "" && (
          <BottomPopup
            onClose={() => {
              setSendProfile(false);
            }}
          >
            <div className="flex flex-col gap-4">
              <div className="BBody20">
                프로필과 함께 보낼 내용을
                <br />
                자유롭게 적어주세요.
              </div>
              <Textarea
                placeholder="자유롭게 적어주세요"
                onChange={(e) =>
                  setSendProfileData({
                    ...sendProfileData,
                    description: e.target.value,
                  })
                }
                className="h-[112px]"
                value={sendProfileData.description}
                maxlength={500}
              />
              <div className="text-Gray05">
                <ol className="list-disc list-inside RCaption14 marker:mr-0">
                  <li className="text-Blue04">
                    {sendProfileData.type === "PREMIUM_DELIVERY"
                      ? "프리미엄 프로필 보내기는 프로필/ 연기 영상 확인 후 별점과 상세 피드백으로 구성되고 스태프 회원의 받은 프로필 내역에도 우선적으로 노출돼요."
                      : "일반 프로필 보내기는 프로필/ 연기 영상 확인 후 별점 or 피드백으로 구성돼요."}
                  </li>
                  <li>
                    스탭 회원의 캐스팅 공고와 제안은 캐스팅보트가 책임지지
                    않으니 승인되지 않은 스탭의 허위 공고 또는 제안을
                    조심하세요.
                  </li>
                  <li>
                    보내는 프로필에 연기영상/자유영상을 첨부하면 캐스팅 확률이
                    높아져요!
                  </li>
                  <li>프로필 받은 스탭은 내 연락처를 열람할 수 있어요.</li>
                </ol>
              </div>
              <ButtonBackground>
                <Button
                  onClick={() => {
                    sendProfileToStaff();
                  }}
                  className="bg-[linear-gradient(90deg,_#406CFF_0%,_#2C55EE_15.5%,_#1C39D9_56%,_#1A3CF3_100%)] w-full py-3 MBody16 text-white border-none"
                  text={`${myRole === "ROLE_MANAGER" ? usePoint : point}포인트로 ${sendProfileData.type === "PREMIUM_DELIVERY" ? "프리미엄 " : ""}프로필 보내기`}
                ></Button>
              </ButtonBackground>
            </div>
          </BottomPopup>
        )}
        <div>
          {myRole === "ROLE_ARTIST" || (myRole === "ROLE_MANAGER" && StaffData?.role?.code !== "MANAGER")
            ? (
              <>
                <div className="flex items-center mt-4 gap-3">
                  <FillButton
                    disabled={isPending}
                    onClick={() => {
                      setSendProfileData({
                        ...sendProfileData,
                        type: "DELIVERY",
                        staffId: StaffData?.memberId!,
                        profileIds: [loginInfo?.profileId!]
                      });
                      if (myRole === "ROLE_MANAGER") {
                        setArtistSelectModal(true)
                      } else {
                        if (!hasProfile) {
                          navigate("/artist/my");
                        } else {
                          setSendProfile(true);
                        }
                      }
                    }}
                    className="w-full"
                    text="프로필 보내기"
                    variant={"Secondary"}
                    size="M"
                  />
                  <button
                    disabled={isPending}
                    onClick={() => {
                      setSendProfileData({
                        ...sendProfileData,
                        type: "PREMIUM_DELIVERY",
                        staffId: StaffData?.memberId!,
                        profileIds: [loginInfo?.profileId!]
                      });
                      if (myRole === "ROLE_MANAGER") {
                        setArtistSelectModal(true)
                      } else {
                        if (!hasProfile) {
                          navigate("/artist/my");
                        } else {
                          setSendProfile(true);
                        }
                      }
                    }}
                    className={`w-full px-5 rounded-md bg-opacity-80 whitespace-pre h-[38px] bg-[linear-gradient(90deg,_#406CFF_0%,_#2C55EE_15.5%,_#1C39D9_56%,_#1A3CF3_100%)] ${isPending && "opacity-40"
                      }`}
                  >
                    <div className="text-Gray00 BBody16 h-[18px]">Premium</div>
                    <div className="text-Gray03 RCaption10">프로필 보내기</div>
                  </button>
                </div>
                {isPending && (
                  <div className="text-[#888B92] mt-4 MCaption12 text-center">
                    승인되지 않은 사용자에게 프로필을 보낼 수 없어요.
                  </div>
                )}
              </>
            ) : ""}
        </div>
        <div className="pt-4">
          {staffRole === "MANAGER" ? (
            <StaffArtistsProfiles
              isManager={state.role === "MANAGER"}
              memberId={StaffData?.memberId!}
            />
          ) : (
            <StaffProductions memberId={StaffData?.memberId!} />
          )}
        </div>
      </div>
    </>
  );
};

export default StaffDetail;
