import React, { useEffect, useMemo } from "react";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import PointCard from "components/PointCard";
import { useNavigate } from "react-router";
import { usePaymentHistory } from "apis/payment";
import { useInView } from "react-intersection-observer";

const PointChargingHistory = () => {
  const navigate = useNavigate();

  const { data, fetchNextPage, hasNextPage } = usePaymentHistory({
    paymentCategory: "POINT",
    size: 24,
  });

  const charge = useMemo(
    () =>
      data ? data?.pages?.flatMap((item) => item.data.content) : [],
    [data]
  );

  const {
    ref,
    inView,
  } = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <HeaderWithBackButton title={"충전 내역"} />
      <div className="p-5 pb-24">
        {charge.length === 0 ? (
          <div className="mt-36 items-center text-center">
            <div className="text-Gray05 RBody14">충전내역이 없어요.</div>
          </div>
        ) : (
          <div className=" flex flex-col gap-4">
            {charge.map((item) => {
              return (
                <PointCard
                  onClick={() => {
                    navigate(`/point/charge/${item.paymentId}`, {
                      state: {
                        cardName: item.cardName,
                        orderAmount: item.orderAmount,
                        orderId: item.orderId,
                        paymentDate: item.paymentDate,
                        paymentId: item.paymentId,
                        price: item.price,
                        canceled: item?.paymentStatus?.code === "CANCELED",
                      },
                    });
                  }}
                  canceled={item?.paymentStatus?.code === "CANCELED"}
                  price={`${item.price}원`}
                  point={item.price}
                  paymentDate={item.paymentDate}
                  key={item.paymentId}
                />
              );
            })}
            {hasNextPage && <div ref={ref} className="h-5 bg-transparent" />}
          </div>
        )}
      </div>
    </div>
  );
};

export default PointChargingHistory;
