import React from "react";

import HeaderWithBackButton from "components/HeaderWithBackButton";
import { useNavigate } from "react-router";
import { useMyInfo } from "apis/my";
import { useLogout } from "apis/user";
import useLoginInfo from "stores/loginInfo";

const MyPage = () => {
  const navigate = useNavigate();
  const { deleteLoginInfo, setIsLogin } = useLoginInfo();


  const { data } = useMyInfo();
  const { mutate } = useLogout();
  const infoData = data?.data;

  const info = [
    { id: 0, title: "이름", desc: infoData?.name },
    { id: 1, title: "이메일", desc: infoData?.email },
    { id: 2, title: "휴대폰번호", desc: infoData?.phoneNumber },
    { id: 3, title: "소속사", desc: infoData?.agency },
  ];

  return (
    <>
      <HeaderWithBackButton title={"내 정보"} />
      <div className="pb-24 pt-5 px-5">
        <div className="flex flex-col gap-4 w-full items-start">
          {info.map((item, i) => {
            return (
              <div
                key={item.id}
                className="flex w-full max-w-[360px] items-center"
              >
                <div className="w-[89px] text-Gray05 mr-2.5">{item.title}</div>
                <div className="flex-1 MBody16 text-Gray09 text-ellipsis whitespace-nowrap overflow-hidden">
                  {item.desc}
                </div>
                {/* {item.id !== 0 && <ArrowRight />} */}
              </div>
            );
          })}
        </div>
        <hr className="my-7" />
        <div className="flex items-start flex-col MBody15 text-Gray05 gap-4">
          <div
            className="cursor-pointer"
            onClick={() => {
              deleteLoginInfo();
              mutate();
              setIsLogin(false);
            }}
          >
            로그아웃
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigate("/withdrawal");
            }}
          >
            탈퇴하기
          </div>
        </div>
      </div>
    </>
  );
};

export default MyPage;
